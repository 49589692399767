import styled from 'styled-components'
import { spacing, mq, maxWidths } from '../../styles/utilities/variables'

const getMaxWidth = size => {
  const sizes = {
    narrowest: maxWidths.narrowestContainer,
    narrow: maxWidths.narrowContainer,
    normal: maxWidths.container,
    wide: maxWidths.wideContainer,
  }
  return sizes[size]
}

const StyledContainer = styled.div`
  max-width: ${props =>
    props.size ? getMaxWidth(props.size) : getMaxWidth('normal')};
  margin: 0 auto;
  width: 100%;
  padding: 0 ${spacing.large};
  position: relative;
  @media ${mq.tablet} {
    padding: 0 ${spacing.medium};
  }
`

export default StyledContainer
