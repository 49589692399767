exports.svStrings = [
  {
    name: 'PICKUP',
    value: 'UTLÄMNINGSSTÄLLE',
    fieldGroupName: 'string_list',
  },
  {
    name: 'vat',
    value: 'Ink moms',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Account Page',
    value: 'Mina Sidor',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Register',
    value: 'Registrera',
    fieldGroupName: 'string_list',
  },
  {
    name: 'My orders',
    value: 'Beställningar',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Account',
    value: 'Konto',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Logout',
    value: 'Logga ut',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Date',
    value: 'Datum',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Status',
    value: 'Status',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Hi',
    value: 'Hej',
    fieldGroupName: 'string_list',
  },
  {
    name: 'loading...',
    value: 'läser in...',
    fieldGroupName: 'string_list',
  },
  {
    name: 'no orders',
    value: 'inga beställningar',
    fieldGroupName: 'string_list',
  },
  {
    name: 'more info',
    value: 'Mer info',
    fieldGroupName: 'string_list',
  },
  {
    name: 'BILLING',
    value: 'FAKTURERING',
    fieldGroupName: 'string_list',
  },
  {
    name: 'SHIPPING',
    value: 'FRAKT',
    fieldGroupName: 'string_list',
  },
  {
    name: 'VAT',
    value: 'Moms',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Total incl VAT',
    value: 'Totalt inkl moms',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Payment method',
    value: 'Betalningsmetod',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Profile',
    value: 'Profil',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Update',
    value: 'Uppdatera',
    fieldGroupName: 'string_list',
  },
  {
    name: 'username',
    value: 'Användarnamn',
    fieldGroupName: 'string_list',
  },
  {
    name: 'email',
    value: 'e-post',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Profile Page',
    value: 'Konto',
    fieldGroupName: 'string_list',
  },
  {
    name: 'order number',
    value: 'order nummer',
    fieldGroupName: 'string_list',
  },
  {
    name: 'password',
    value: 'Lösenord',
    fieldGroupName: 'string_list',
  },
  {
    name: 'repeat password',
    value: 'repetera lösenord',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Search',
    value: 'Sök',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Have an account ? Login here',
    value: 'Har ett konto ? Logga in',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Reset password',
    value: 'Återställ lösenord',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Reset link',
    value: 'Återställ länk',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Login instead',
    value: 'Logga in istället',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Sing in',
    value: 'Logga in',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Login',
    value: 'Logga in',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Forgot password?',
    value: 'Glömt ditt lösenord?',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Update password',
    value: 'Uppdatera lösenord',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Order',
    value: 'Köp',
    fieldGroupName: 'string_list',
  },
  {
    name: 'product',
    value: 'Produkt',
    fieldGroupName: 'string_list',
  },
  {
    name: 'artice number',
    value: 'Artikelnummer',
    fieldGroupName: 'string_list',
  },
  {
    name: 'quantity',
    value: 'sku',
    fieldGroupName: 'string_list',
  },
  {
    name: 'paid',
    value: 'Summa',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Updated!',
    value: 'Uppdaterat!',
    fieldGroupName: 'string_list',
  },
  {
    name: 'article number',
    value: 'artikelnummer',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Order',
    value: 'Köp',
    fieldGroupName: 'string_list',
  },
  {
    name: 'logging in',
    value: 'loggar in',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Register',
    value: 'Registrera',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Account',
    value: 'Konto',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Logout',
    value: 'Logga ut',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Register',
    value: 'Registrera',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Login',
    value: 'Logga in',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Back',
    value: 'Tillbaka',
    fieldGroupName: 'string_list',
  },
  {
    name: 'ORDER',
    value: 'ORDER',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Date',
    value: 'Datum',
    fieldGroupName: 'string_list',
  },
  {
    name: 'loginPage',
    value: 'Login Sida',
    fieldGroupName: 'string_list',
  },
  {
    name: 'completed',
    value: 'avslutad',
    fieldGroupName: 'string_list',
  },
  {
    name: 'processing',
    value: 'bearbetning',
    fieldGroupName: 'string_list',
  },
  {
    name: 'pending payment',
    value: 'väntar på betalning',
    fieldGroupName: 'string_list',
  },
  {
    name: 'on hold',
    value: 'väntar',
    fieldGroupName: 'string_list',
  },
  {
    name: 'cancel',
    value: 'avbruten',
    fieldGroupName: 'string_list',
  },
  {
    name: 'refunded',
    value: 'återbetalas',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Edit profile',
    value: 'Redigera profil',
    fieldGroupName: 'string_list',
  },
  {
    name: 'cancelled',
    value: 'avbruten',
    fieldGroupName: 'string_list',
  },
  {
    name: 'completed',
    value: 'avslutad',
    fieldGroupName: 'string_list',
  },
  {
    name: 'failed',
    value: 'misslyckades',
    fieldGroupName: 'string_list',
  },
  {
    name: 'processing',
    value: 'bearbetning',
    fieldGroupName: 'string_list',
  },
  {
    name: 'on hold',
    value: 'placerad',
    fieldGroupName: 'string_list',
  },
  {
    name: 'refunded',
    value: 'Återbetalas',
    fieldGroupName: 'string_list',
  },
  {
    name: 'pending',
    value: 'Väntande',
    fieldGroupName: 'string_list',
  },
  {
    name: 'no info',
    value: '-',
    fieldGroupName: 'string_list',
  },
  {
    name: 'directionsText',
    value: 'Vägbeskrivning',
    fieldGroupName: 'string_list',
  },
]
