import styled, { css } from 'styled-components'
import { colors } from '../../styles/utilities/variables'
import { H1, H2, H3, H4, H5, H6 } from '../../styles/base/globalStyles'

const defaultStyle = css`
  color: ${props =>
    props.color ? colors[props.color] : colors.primaryDarkBlue};
  // font-weight: bold;
`

const sizes = {
  1: H1,
  2: H2,
  3: H3,
  4: H4,
  5: H5,
  6: H6,
}

const StyledHeading = styled.div``

StyledHeading.h1 = styled.h1`
  ${defaultStyle}
  ${props => (props.size ? sizes[props.size] : H1)}
  color: ${props =>
    props.color === 'primaryRed' ? colors.primaryRed : colors.primaryDarkBlue};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`
StyledHeading.h2 = styled.h2`
  ${defaultStyle}
  ${props => (props.size ? sizes[props.size] : H2)};
`
StyledHeading.h3 = styled.h3`
  ${defaultStyle}
  ${props => (props.size ? sizes[props.size] : H3)}
`
StyledHeading.h4 = styled.h4`
  ${defaultStyle}
  ${props => (props.size ? sizes[props.size] : H4)}
`
StyledHeading.h5 = styled.h5`
  ${defaultStyle}
  ${props => (props.size ? sizes[props.size] : H5)}
`
StyledHeading.h6 = styled.h6`
  ${defaultStyle}
  ${props => (props.size ? sizes[props.size] : H6)}
`

export default StyledHeading
