/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { getCurrentLang } from '../../helpers/lang'

function SEO({
  title,
  description,
  keywords,
  canonical,
  openGraphType,
  openGraphTitle,
  openGraphDescription,
  openGraphImage,
  lang = 'en',
  schema,
  meta,
}) {
  const currentLang = getCurrentLang()
  return (
    <Helmet
      htmlAttributes={{
        currentLang,
      }}
      title={title}
      keywords={keywords}
      canonical={canonical}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: openGraphTitle,
        },
        {
          property: `og:description`,
          content: openGraphDescription,
        },
        {
          property: `og:type`,
          content: openGraphType,
        },
        {
          property: `og:image`,
          content: openGraphImage,
        },
        {
          name: `twitter:title`,
          content: openGraphTitle,
        },
        {
          name: `twitter:description`,
          content: openGraphDescription,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ].filter(m => m && m.content)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
