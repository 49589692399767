import React from 'react'
import PropTypes from 'prop-types'
import StyledContentExpander from './style'

const ContentExpander = props => {
  const { children, open, duration = 0.55 } = props

  return (
    <StyledContentExpander
      animate={{ height: open ? 'auto' : 0 }}
      initial={false}
      transition={{ duration: duration }}
    >
      {children}
    </StyledContentExpander>
  )
}

ContentExpander.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
}

export { ContentExpander }
