import styled, { css } from 'styled-components'
import { colors, spacing, typography } from '../../styles/utilities/variables'

const StyledCartIcon = styled.div`
  display: inline-flex;
  padding: ${props =>
    props.border ? `${spacing.small} 22px 12px ${spacing.small}` : '0'};
  border: ${props => (props.border ? `1px solid ${colors.lightGrey}` : '')};
  border-radius: 999rem;
  cursor: pointer;
`

StyledCartIcon.Text = styled.p`
  margin: 0;
  margin-left: ${spacing.base};
`

StyledCartIcon.IconWrapper = styled.div`
  position: relative;
  line-height: 1;
`

const numberSize = `17px`
StyledCartIcon.Number = styled.div`
  background: ${colors.secondaryLightBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primaryDarkBlue};
  position: absolute;
  top: -${spacing.xSmall};
  right: -${spacing.xSmall};
  border-radius: 100%;
  height: ${numberSize};
  width: ${numberSize};
  line-height: ${numberSize};
  text-align: center;
  font-size: ${typography.sizes.xxSmall};
  transform: scale(1) translateY(0px);
  transition: transform 0.3s;

  ${props =>
    props.bounce === true &&
    css`
      transition: transform 0.1s;
      transform: scale(1.2) translateY(-${spacing.xxSmall});
    `}

  ${props =>
    props.isActive &&
    css`
      && {
        .price {
          transform: translateY(20px);
          font-size: 12px;
          .featured {
            width: 100%;
          }
        }
        .extra {
          transform: translateX(0px);
        }
      }
    `}
`

export default StyledCartIcon
