import styled, { css } from 'styled-components'
import { spacing, mq } from '../../styles/utilities/variables'

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.col ? 'repeat(' + props.col + ', 1fr)' : '1fr'};
  grid-column-gap: ${props =>
    props.colGap ? spacing[props.colGap] : spacing.large};
  grid-row-gap: ${props =>
    props.rowGap ? spacing[props.rowGap] : spacing.large};

  text-align: ${props => (props.center ? 'center' : 'left')};
  align-items: ${props => (props.align ? props.align : 'start')};
  justify-items: ${props => (props.justify ? props.justify : 'start')};
  text-align: ${props => (props.text ? props.text : 'left')};

  .no-cssgrid & {
    display: flex;
  }

  .row-reverse {
    flex-direction: row-reverse;
  }

  & > * {
    width: 100%;

    .no-cssgrid & {
      width: ${props =>
        props.col ? `calc(${100 / props.col}% - ${spacing.large})` : 'auto'};
      margin-right: ${props =>
        props.colGap ? spacing[props.colGap] : spacing.large};
      margin-bottom: ${props =>
        props.rowGap ? spacing[props.rowGap] : spacing.large};
    }
  }

  @media ${mq.mobile} {
    grid-template-columns: 1fr;
    grid-row-gap: ${spacing.medium};
  }

  ${props =>
    props.autoflow &&
    css`
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-auto-rows: minmax(350px, 400px);
      grid-auto-flow: dense;

      .no-cssgrid & {
        & > * {
          width: calc(33% - ${spacing.base});
        }
      }
    `}

  ${props =>
    props.tabletCol &&
    css`
      @media ${mq.tablet} {
        grid-template-columns: repeat(${props => props.tabletCol}, 1fr);
        grid-row-gap: 3.6rem;
        grid-column-gap: 2.6rem;
      }
    `}

  ${props =>
    props.mobileCol &&
    css`
      @media ${mq.mobile} {
        grid-template-columns: repeat(${props => props.mobileCol}, 1fr);
        grid-row-gap: 3.6rem;
        grid-column-gap: 2.6rem;
      }
    `}
`

export default StyledGrid
