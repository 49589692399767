import React, { useState } from 'react'
import PropTypes from 'prop-types'
import StyledMobileMenu from './style'
import { Icon } from '../../components/Icon'
import { HasContent } from './Content'
import { isInternal } from '../../helpers/link'

const NavItem = props => {
  const {
    children,
    label,
    to,
    content,
    hascontent,
    internClick,
    handleClick,
  } = props

  const [open, setOpen] = useState(false)

  const Component = to
    ? isInternal(to)
      ? StyledMobileMenu.NavItemInternal
      : StyledMobileMenu.NavItemExternal
    : StyledMobileMenu.NavItemButton

  return internClick || to ? (
    <>
      <Component
        to={to && isInternal(to) ? to : null}
        href={to && !isInternal(to) ? to : null}
        onClick={internClick ? () => setOpen(!open) : null}
        hascontent={hascontent ? 1 : 0}
        open={open}
      >
        {label}
        {hascontent && <Icon icon={'keyboard_arrow_down'} size={28} />}
      </Component>
      {hascontent && <HasContent content={content} open={open} />}
    </>
  ) : (
    <StyledMobileMenu.NavItem onClick={handleClick}>
      {label}
      {children}
    </StyledMobileMenu.NavItem>
  )
}

NavItem.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  to: PropTypes.string,
  content: PropTypes.array,
  hascontent: PropTypes.bool,
  internClick: PropTypes.bool,
  handleClick: PropTypes.func,
}

export { NavItem }
