import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import {
  AppContext,
  closeCart,
  removeProductFromCart,
  updateProductQuantity,
} from '../../config/context'
import { SPACING, COLORS } from '../../helpers/constants'
import { getPriceValue, formatPrice } from '../../helpers/utilities'
import { getProductsInCart } from '../../helpers/checkout'
import StyledCart from './style'
import { Heading } from '../Heading'
import { Divider } from '../Divider'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { useTranslation } from '../../hooks/useTranslation'
import noImageFallback from '../../assets/images/noImageFallback.png'
import { MakeImage } from '../MakeImage'
import { Form } from '../Form'

const Cart = props => {
  const [state, dispatch] = useContext(AppContext)
  const { products, productsInCart } = state

  const getTranslation = useTranslation()

  const filteredProducts = getProductsInCart(products, productsInCart)

  const totalPrice = filteredProducts.reduce(
    (acc, product) =>
      product.price
        ? acc + getPriceValue(product.price) * product.quantity
        : acc,
    0
  )

  const handleToCheckoutClick = () => {
    navigate('/checkout/')
    setTimeout(() => {
      dispatch(closeCart())
    }, 200)
  }

  const { inCheckout = false } = props

  return (
    <StyledCart inCheckout={inCheckout}>
      {!inCheckout && (
        <>
          <StyledCart.CloseCartButton
            onClick={() => {
              dispatch(closeCart())
            }}
          >
            <Icon size={32} icon={'clear'} />
          </StyledCart.CloseCartButton>

          <StyledCart.Header inCheckout={inCheckout}>
            <StyledCart.HeaderInner>
              <Heading size={2} type={'h2'}>
                {getTranslation('insideCartInformationText')}
              </Heading>
            </StyledCart.HeaderInner>
          </StyledCart.Header>
        </>
      )}

      <StyledCart.Body inCheckout={inCheckout}>
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product, index) => {
            return (
              <React.Fragment key={product.id}>
                <CartProduct
                  key={product.id}
                  id={product.id}
                  name={product.name}
                  sku={product.sku}
                  price={product.price}
                  image={product.image}
                  quantity={product.quantity}
                  handleRemoveClick={id => {
                    dispatch(removeProductFromCart(id))
                  }}
                  handleQuantityChange={(id, value) => {
                    dispatch(updateProductQuantity(id, value))
                  }}
                />
                {index < filteredProducts.length - 1 && (
                  <Divider
                    size={SPACING.MEDIUM}
                    line={COLORS.PRIMARY_DARK_BLUE}
                  />
                )}
              </React.Fragment>
            )
          })
        ) : (
          <StyledCart.EmptyMessage inCheckout={inCheckout}>
            {getTranslation('emptyCartInformationText')}
          </StyledCart.EmptyMessage>
        )}
      </StyledCart.Body>

      <StyledCart.Footer>
        <StyledCart.FooterInner inCheckout={inCheckout}>
          <StyledCart.TextRow>
            <span>{getTranslation('totalAmountUppercaseText')}</span>
            <span>{formatPrice(totalPrice)} KR</span>
          </StyledCart.TextRow>

          {!inCheckout && (
            <>
              <Divider size={SPACING.X_SMALL} />
              <Button
                large
                fullWidth
                disabled={!filteredProducts.length}
                textColor={COLORS.SECONDARY_LIGHT_BLUE}
                icon={'arrow_forward'}
                handleClick={handleToCheckoutClick}
              >
                {getTranslation('continueToCheckoutText')}
              </Button>
            </>
          )}
        </StyledCart.FooterInner>
      </StyledCart.Footer>
    </StyledCart>
  )
}

const CartProduct = props => {
  const {
    id,
    name,
    sku,
    price,
    image,
    quantity,
    handleRemoveClick,
    handleQuantityChange,
  } = props

  const getTranslation = useTranslation()

  const quantityOptions = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' },
    { value: 5, text: '6' },
    { value: 7, text: '7' },
    { value: 8, text: '8' },
    { value: 9, text: '9' },
    { value: 10, text: '10' },
  ]

  const productImage =
    image && image.makeImageSmall ? image.makeImageSmall : null

  return (
    <StyledCart.CartProduct>
      {productImage ? (
        <StyledCart.CartProductImageWrapper>
          <MakeImage makeImage={productImage} />
        </StyledCart.CartProductImageWrapper>
      ) : (
        <StyledCart.CartProductImage src={noImageFallback} />
      )}

      <StyledCart.CartProductText>
        <p>{name}</p>
        {sku && (
          <p>
            {getTranslation('singleProductNumberText')}: {sku}
          </p>
        )}
        {price ? (
          <p>
            {`${formatPrice(price)} ${getTranslation(
              'singleProductPriceText'
            )}`}
          </p>
        ) : (
          <p>{getTranslation('noPriceErrorMessage')}</p>
        )}
        <Divider size={SPACING.XX_SMALL} />
        <Form.ControlledSelect
          // placeholder={placeholder}
          name={`quantity_${id}`}
          options={quantityOptions.map(option => {
            return { value: option.value, name: option.text }
          })}
          // innerRef={register}
          // inValid={inValid}
          value={quantity}
          handleChange={e => {
            handleQuantityChange(id, e.target.value)
          }}
        />
      </StyledCart.CartProductText>
      <StyledCart.RemoveButton onClick={() => handleRemoveClick(id)}>
        <Icon size={20} icon={'delete_outline'} />
      </StyledCart.RemoveButton>
    </StyledCart.CartProduct>
  )
}

Cart.propTypes = {
  inCheckout: PropTypes.bool,
}

export { Cart }
