import { createGlobalStyle } from 'styled-components'
import {
  colors,
  typography,
  spacing,
  mq,
  cssEasings,
} from '../utilities/variables'
import { headerHeights } from '../../views/Header/style'
require('bootstrap/dist/css/bootstrap-grid.min.css')

export const H1 = `
  font-size: ${typography.sizes.xLarge};

  @media ${mq.mobile} {
    font-size: ${typography.sizes.large};
  }
`
export const H2 = `
  font-size: ${typography.sizes.large};
`
export const H3 = `
  font-size: ${typography.sizes.medium};
`
export const H4 = `
  font-size: ${typography.sizes.base};
`
export const H5 = `
  font-size: ${typography.sizes.base};
`
export const H6 = `
  font-size: ${typography.sizes.base};
`

export const GlobalStyle = createGlobalStyle`
  /* Material Icons moved to gatsby-config.js */

  html,
  body {
    font-family: ${typography.primaryFont};
  }

  html {
    /* Lets hold on with this one */
    /* @media ${mq.specialHeadingRoom} {
      font-size: 55%;
    } */

    @media ${mq.tablet} {
      font-size: 50%;
    }

    @media ${mq.tabletPortrait} {
      font-size: 40%;
    }

    @media ${mq.mobile} {
      font-size: 60%;
    }
  }

body {
    color: ${colors.black};
    font-size: ${typography.sizes.base};
    padding-top: ${headerHeights.desktop} !important;
    background: ${colors.adaptedLightBlueBackground} !important;

    @media ${mq.mobile} {
      padding-top: ${headerHeights.mobile};
    }

    .intercom-lightweight-app {
      opacity: 1;
      transition: opacity 0.3s;
    }

    &.is-preview {
      .intercom-lightweight-app {
        display: none !important;
      }
    }
  }

  input {
    font-size: ${typography.sizes.base};
  }

  input:checked + label:after{
    top:0 !important;
    left:0 !important;
  }

  a {
    text-decoration: none;
  }

  p {
    margin-bottom: ${spacing.small} !important;
  }

  strong {
    font-weight: bold;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: ${typography.secondaryFont};
    font-weight: normal;
  }

  h1 {
    ${H1}
    @media ${mq.mobile} {
      font-size: 30px;
    }
  }
  h2 {
    ${H2}
    @media ${mq.mobile} {
      font-size: ${typography.sizes.xLarge};
    }
  }
  h3 {
    ${H3}
  }
  h4 {
    ${H4}
  }
  h5 {
    ${H5}
  }
  h6 {
    ${H6}
  }

  code, kbd, pre, samp {
    font-family: monospace;
    background: #e6e6e6;
    padding: .1em .2em;
    margin: 0.1em;
    padding: .25ex .75ch;
  }

  /* Lightbox adjustments here for now */
  .eco-lightbox {
    background: rgba(0, 0, 0, .8);
    transition: opacity .3s ease;
    z-index: 10000;

    .basicLightbox__placeholder {
      transition: transform .3s ${cssEasings.easeOutCubic};
    }

    &.basicLightbox--visible {
      .basicLightbox__placeholder {
        transition: transform .5s ${cssEasings.easeOutCubic};
      }
    }
  }

  /* Lightbox gallery */

  .eco-lightbox-gallery {
    background: ${colors.white};
    transition: opacity .3s ease;
    z-index: 10000;

    .basicLightbox__placeholder {
      transition: transform .3s ${cssEasings.easeOutCubic};
      height: 100%;
      width: 100%;

      & .slick-slider, .slick-list, .slick-track   {
        height: 100%;
      }
      & .slick-slide {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: ${spacing.large} 0;
      }
    }

    &.basicLightbox--visible {
      .basicLightbox__placeholder {
        transition: transform .5s ${cssEasings.easeOutCubic};
      }
    }
  }

  .eco-lightbox-video {

    .basicLightbox__placeholder {
      width: 100%;
      max-width: 80vw;
    }

    .iframe-wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`
