const { format } = require('date-fns')

/**
 * Validate URL
 * @param {*} str
 */
const validURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

/**
 * Get url path
 * @param {*} url
 */
const getUrl = url => {
  try {
    var parser = new URL(url)
    const pathname = parser.pathname
    if (['/front-page', '/home'].includes(pathname)) return '/'
    return pathname
  } catch (error) {
    return url
  }
}

/**
 * Is string ISO date
 * @param {*} str
 */
const isIsoDate = str => {
  var pattern = new RegExp(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/, 'i')
  return !!pattern.test(str)
}

/**
 * Get date compatible with Gravity Forms
 * @param {*} str
 */
const getGFCompatibleDate = str => {
  const date = new Date(str)
  return format(date, 'yyyy-MM-dd')
}

/**
 * SITE CONSTANTS
 */
const SITE_URL = process.env.GATSBY_SITE_URL ? process.env.GATSBY_SITE_URL : `/`
const CMS_URL = process.env.GATSBY_CMS_URL
  ? process.env.GATSBY_CMS_URL
  : 'https://eco.raket.co'
const LANG = process.env.GATSBY_LANG ? process.env.GATSBY_LANG : 'SV'

/**
 * WP CONSTANTS
 */
const WP_CONSUMER_KEY = process.env.WOOCOMMERCE_CONSUMER_KEY
  ? process.env.WOOCOMMERCE_CONSUMER_KEY
  : null
const WP_CONSUMER_SECRET = process.env.WOOCOMMERCE_CONSUMER_SECRET
  ? process.env.WOOCOMMERCE_CONSUMER_SECRET
  : null

/**
 * KLARNA CREDENTIALS
 */
const KLARNA_BASE_URL = process.env.GATSBY_KLARNA_BASE_URL
  ? process.env.GATSBY_KLARNA_BASE_URL
  : null
const KLARNA_USERNAME = process.env.GATSBY_KLARNA_USERNAME
  ? process.env.GATSBY_KLARNA_USERNAME
  : null
const KLARNA_PASSWORD = process.env.GATSBY_KLARNA_PASSWORD
  ? process.env.GATSBY_KLARNA_PASSWORD
  : null

/**
 * CUSTOMER SETTINGS
 */
const CUSTOMER_CURRENCY = process.env.CUSTOMER_CURRENCY
  ? process.env.CUSTOMER_CURRENCY
  : 'SEK'

const CUSTOMER_COUNTRY_CODE = process.env.CUSTOMER_COUNTRY_CODE
  ? process.env.CUSTOMER_COUNTRY_CODE
  : 'SE'

const CUSTOMER_LOCALE = process.env.CUSTOMER_LOCALE
  ? process.env.CUSTOMER_LOCALE
  : 'sv-SE'

const COUNTRY_CODE = process.env.GATSBY_COUNTRY_CODE
  ? process.env.GATSBY_COUNTRY_CODE
  : 'SE'

module.exports = {
  getUrl,
  validURL,
  isIsoDate,
  getGFCompatibleDate,
  SITE_URL,
  CMS_URL,
  LANG,
  WP_CONSUMER_KEY,
  WP_CONSUMER_SECRET,
  KLARNA_BASE_URL,
  KLARNA_USERNAME,
  KLARNA_PASSWORD,
  CUSTOMER_CURRENCY,
  CUSTOMER_COUNTRY_CODE,
  CUSTOMER_LOCALE,
  COUNTRY_CODE,
}
