import { useState, useEffect } from 'react'
import { mqSizes } from '../styles/utilities/variables'
import { getIsClient } from '../helpers/utilities'

const calculateBreakpoints = width => {
  const breakpoints = { ...mqSizes }
  Object.entries(mqSizes).forEach(([key, val]) => {
    breakpoints[key] = val >= width
  })
  return breakpoints
}

const getWindowSize = () => {
  if (typeof window === 'undefined') {
    return { width: undefined, height: undefined }
  }
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

const useResponsive = () => {
  const isClient = getIsClient()

  const [windowSize, setWindowSize] = useState(getWindowSize)
  const [breakpoints, setBreakpoints] = useState(
    calculateBreakpoints(windowSize.width)
  )
  useEffect(() => {
    if (!isClient) return false

    const handleResize = () => {
      const windowSize = getWindowSize()
      setWindowSize(windowSize)
      setBreakpoints(calculateBreakpoints(windowSize.width))
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isClient])

  return {
    windowSize,
    breakpoints,
  }
}

export { useResponsive }
