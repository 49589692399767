import dayjs from 'dayjs'
import queryString from 'query-string'

/**
 * Format date string
 * @param {*} string
 */
export const formatDate = string => {
  const date = dayjs(string)
  return date.format('D MMMM YYYY')
}

export const logger = string => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('logger', string)
  }
}

export const parseVerticalAlign = align => {
  switch (align) {
    case 'center':
      return 'center'
    case 'top':
      return 'flex-start'
    case 'bottom':
      return 'flex-end'
    default:
  }
}

export const getIsClient = () => {
  return typeof window === 'object'
}

export const getPriceValue = priceString => {
  return parseInt(priceString.replace(/[\s+a-z&;]+/g, ''))
}

export const formatPrice = price => {
  if (price) {
    let priceValue = getPriceValue(price.toString())
    return priceValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
  return price
}

export const capitalize = string => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getYoutubeId = url => {
  let youtubeRegex = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  return url ? url.match(youtubeRegex)[1] : ''
}

/**
 * Query type parser
 */
export const getQueryType = type => {
  switch (type) {
    case 'eco_serie':
      return 'serie'
      break
    case 'eco_dealer':
      return 'dealer'
      break
    case 'eco_lexicon':
      return 'word'
      break
    case 'eco_faq':
      return 'faq'
      break
    default:
      return type
  }
}

/**
 * Get query params
 */
export const getQueryParams = () => {
  const parsed =
    typeof window !== 'undefined' && window.location
      ? queryString.parse(window.location.search)
      : {}
  return parsed
}

/**
 * Export page data
 */
export const usePageData = (data, pageContext) => {
  let returnData = {}
  if (pageContext) returnData = { ...returnData, ...pageContext.data }
  if (data) returnData = { ...returnData, ...data.wordpress }
  return returnData
}

export const validateField = (type, value) => {
  switch (type) {
    case 'email':
      return /\S+@\S+\.\S+/.test(value)
      break
    case 'value':
      return value && value.length
      break
    case 'min6':
      return value && value.length >= 6
      break
  }
}
