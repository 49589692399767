/* eslint-disable */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import StyledForm from './style'

const Form = props => {
  return <StyledForm {...props}>{props.children}</StyledForm>
}

Form.Group = props => {
  return <StyledForm.Group {...props}>{props.children}</StyledForm.Group>
}

Form.Description = props => {
  return <StyledForm.Description>{props.children}</StyledForm.Description>
}

Form.Error = props => {
  return <StyledForm.Error>{props.children}</StyledForm.Error>
}

Form.Label = props => {
  return <StyledForm.Label {...props}>{props.children}</StyledForm.Label>
}

Form.Input = props => {
  return (
    <StyledForm.Input ref={props.innerRef} autoComplete={'off'} {...props} />
  )
}

Form.TextArea = props => {
  return (
    <StyledForm.TextArea ref={props.innerRef} {...props}>
      {props.children}
    </StyledForm.TextArea>
  )
}

Form.Radio = props => {
  return <StyledForm.Radio ref={props.innerRef} {...props} />
}

Form.Select = props => {
  const { options } = props
  return (
    <StyledForm.Select ref={props.innerRef} {...props}>
      {options.map((option, key) => {
        return (
          <option key={key} value={option.value}>
            {option.name}
          </option>
        )
      })}
    </StyledForm.Select>
  )
}

Form.ControlledSelect = props => {
  const { options } = props
  return (
    <StyledForm.Select
      ref={props.innerRef}
      {...props}
      onChange={e => {
        props.handleChange(e)
      }}
    >
      {options.map((option, key) => {
        return (
          <option key={key} value={option.value}>
            {option.name}
          </option>
        )
      })}
    </StyledForm.Select>
  )
}

Form.Checkbox = props => {
  return (
    <label htmlFor={props.id}>
      <StyledForm.Checkbox>
        <StyledForm.InputWrapper>
          <input
            id={props.id}
            ref={props.innerRef}
            type='checkbox'
            {...props}
          />
          <span className={'checkmark'}></span>
        </StyledForm.InputWrapper>
        {props.label && <span className={'label'}>{props.label}</span>}
      </StyledForm.Checkbox>
    </label>
  )
}

Form.ControlledCheckbox = props => {
  return (
    <label htmlFor={props.id}>
      <StyledForm.Checkbox>
        <StyledForm.InputWrapper>
          <input
            id={props.id}
            type='checkbox'
            checked={props.checked}
            onChange={props.handleChange}
          />
          <span className={'checkmark'}></span>
        </StyledForm.InputWrapper>
        {props.label && <span className={'label'}>{props.label}</span>}
      </StyledForm.Checkbox>
    </label>
  )
}

Form.Radio = props => {
  return (
    <label htmlFor={props.id}>
      <StyledForm.Radio>
        <StyledForm.InputWrapper>
          <input id={props.id} ref={props.innerRef} type='radio' {...props} />
          <span className={'checkmark'}></span>
        </StyledForm.InputWrapper>
        {props.label && <span className={'label'}>{props.label}</span>}
      </StyledForm.Radio>
    </label>
  )
}

Form.Date = props => {
  const { placeholder, name, register, isRequired, control } = props
  return (
    <StyledForm.DateWrapper>
      <Controller
        control={control}
        name={name}
        valueName={'selected'} // DateSelect value's name is selected
        defaultValue={''}
        render={({ onChange, onBlur, value }) => (
          <DatePicker
            onChange={onChange}
            // onBlur={onBlur}
            selected={value}
            customInput={<StyledForm.Input type={'text'} />}
            placeholderText={placeholder}
            dateFormat={'yyyy-MM-dd'}
          />
        )}
      />
    </StyledForm.DateWrapper>
  )
}

Form.propTypes = {
  children: PropTypes.any,
}

Form.Label.propTypes = {
  children: PropTypes.any,
}

Form.Group.propTypes = {
  children: PropTypes.any,
}

Form.Input.propTypes = {
  name: PropTypes.string,
  valid: PropTypes.bool,
  inValid: PropTypes.bool,
  innerRef: PropTypes.any,
}

Form.TextArea.propTypes = {
  name: PropTypes.string,
  innerRef: PropTypes.any,
  children: PropTypes.any,
}

Form.Select.propTypes = {
  options: PropTypes.array,
  innerRef: PropTypes.any,
}

Form.Checkbox.propTypes = {
  innerRef: PropTypes.any,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
}

Form.Radio.propTypes = {
  innerRef: PropTypes.any,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
}

export { Form }
