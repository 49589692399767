import React, { useState, useEffect, useRef, useCallback } from 'react'
import StyledImage from './style'
import { getImageUrl } from '../../helpers/image'
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'
import { stripTags } from '../../helpers/texts'

/**
 * Cache Management
 */
const cache = new Set()
const isImageCached = url => {
  if (!url) return false
  return cache.has(url)
}
const addImageToCache = url => {
  if (url) {
    cache.add(url)
  }
}

/**
 * Image Component
 * @param {*} props
 */
const MakeImage = props => {
  /**
   * Props extraction
   */
  const critical = props.critical
  const alt = props.altText ? props.altText : props.alt
  const caption = props.caption ? props.caption : null
  const base64 = props.makeImage ? props.makeImage.base64 : null
  const url = props.makeImage ? props.makeImage.url : getImageUrl(props)
  const align = props.imageAlign ? props.imageAlign : null
  const widthPercentage = props.widthPercentage
    ? `${props.widthPercentage}%`
    : '100%'
  const coverBackground = props.coverBackground ? props.coverBackground : null

  /**
   * Srcset
   */
  let srcSet = null
  let srcSetWebp = null
  let maxWidth = props.maxWidth ? props.maxWidth : 1000
  if (props.makeImage) {
    srcSet = props.makeImage.srcSet
      ? props.makeImage.srcSet.join(', ')
      : props.makeImage.url
    srcSetWebp = props.makeImage.srcSetWebp
      ? props.makeImage.srcSetWebp.join(', ')
      : props.makeImage.webpUrl
    maxWidth = props.makeImage.width ? props.makeImage.width : maxWidth
  }

  /**
   * State managements
   */
  const imageWrapperRef = useRef(null)
  const [isLoaded, setLoaded] = useState(() => isImageCached(url))
  const [isVisible, setVisible] = useState(!!critical)

  /**
   * Add Image to cache
   */
  useEffect(() => {
    if (isLoaded) {
      addImageToCache(url)
    }
  }, [isLoaded, props])

  /**
   * Handle Image load
   */
  const handleLoad = useCallback(() => {
    setLoaded(true)
  })

  /**
   * Handle in view
   */
  useIntersectionObserver({
    target: imageWrapperRef,
    onIntersect: () => {
      setVisible(true)
    },
  })

  /**
   * Make Image
   */
  return (
    <StyledImage
      key={url}
      align={align}
      width={widthPercentage}
      ref={imageWrapperRef}
      isLoaded={isLoaded}
      coverBackground={coverBackground}
    >
      {base64 && (
        <img
          width={maxWidth}
          height={'100%'}
          aria-hidden='true'
          src={base64}
          alt=''
          data-placeholder
        />
      )}
      <picture>
        {isVisible && (
          <>
            {srcSetWebp && (
              <source
                width={maxWidth}
                height={'100%'}
                srcSet={srcSetWebp}
                type='image/webp'
                sizes={`(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`}
              />
            )}
            {srcSet && (
              <source
                width={maxWidth}
                height={'100%'}
                srcSet={srcSet}
                sizes={`(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`}
              />
            )}
          </>
        )}
        <StyledImage.Img
          sizes={`(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`}
          width={maxWidth}
          height={'100%'}
          data-image
          decoding={'async'}
          loading={'lazy'}
          src={isVisible ? url : null}
          alt={alt}
          onLoad={handleLoad}
          objectFit={coverBackground ? 'cover' : 'fill'}
        />
      </picture>
      <noscript>Please enable javascript...</noscript>
      {caption && (
        <StyledImage.Caption
          isLoaded={isLoaded}
          width={widthPercentage}
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      )}
    </StyledImage>
  )
}

export { MakeImage }
