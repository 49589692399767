import { useEffect } from 'react'
import { getIsClient } from '../helpers/utilities'

const useClickOutside = (ref, callback) => {
  const isClient = getIsClient()

  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    if (!isClient) return false

    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export { useClickOutside }
