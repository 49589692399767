import React from 'react'
import PropTypes from 'prop-types'
import Header from '../Header'
import CheckoutHeader from '../CheckoutHeader'
import { ResetStyle } from '../../styles/base/reset'
import { GlobalStyle } from '../../styles/base/globalStyles'
import Footer from '../Footer'
import Main from '../Main'
import Panels from '../Panels'

const Layout = ({
  children,
  hideFooter = false,
  showCheckoutHeader = false,
}) => {
  return (
    <>
      {showCheckoutHeader ? <CheckoutHeader /> : <Header />}
      <ResetStyle />
      <GlobalStyle />
      <Main>{children}</Main>
      {!hideFooter && <Footer />}
      <Panels />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideFooter: PropTypes.bool,
}

export default Layout
