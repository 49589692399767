import styled, { css } from 'styled-components'
import { spacing, colors, typography } from '../../styles/utilities/variables'

const StyledProfileSwitcher = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  padding-top: ${spacing.small};
  min-width: 190px;
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
`

StyledProfileSwitcher.List = styled.ul`
  background: ${colors.white};
  padding: ${spacing.small};
  color: ${colors.primaryDarkBlue};
  margin: 0;
  list-style: none;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
`

StyledProfileSwitcher.ListItem = styled.li`
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: ${spacing.xxSmall};

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: block;
    position: relative;
    color: ${colors.primaryDarkBlue};
    font-weight: normal;
    font-size: ${typography.sizes.small};
    padding: ${spacing.xSmall} ${spacing.xSmall} ${spacing.xSmall} 2.6rem;

    &:focus,
    &:active {
      color: ${colors.primaryDarkBlue};
    }

    &:hover {
      background: ${colors.adaptedLightBlueBackground};
    }
  }

  ${props =>
    props.active &&
    css`
      a {
        &::before {
          display: block;
          width: 1.6rem;
          height: 1.6rem;
          position: absolute;
          top: 0.8rem;
          left: 0.4rem;
          font-family: 'Material Icons';
          content: 'done';
          font-feature-settings: 'liga' 1;
        }
      }
    `}
`

export default StyledProfileSwitcher
