import React from 'react'
import PropTypes from 'prop-types'
import StyledText from './style'

/**
 * Text
 * @param {*} props
 */
const Text = props => {
  const { children, size, columns } = props
  return (
    <StyledText size={size} columns={columns}>
      {children}
    </StyledText>
  )
}

Text.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
  columns: PropTypes.number,
}

export { Text }
