import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import {
  spacing,
  cssEasings,
  colors,
  typography,
} from '../../styles/utilities/variables'
import StyledIcon from '../../components/Icon/style'
import StyledContentExpander from '../../components/ContentExpander/style'

const StyledMobileMenu = styled.aside`
  background: ${colors.secondaryLightBlue};
  padding: 70px ${spacing.large} 0 ${spacing.medium};
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  transform: ${props => (props.open ? 'translateX(0%)' : 'translateX(105%)')};
  transition: transform 0.25s ${cssEasings.easeInOutCubic};

  ${props =>
    props.open &&
    css`
      transition: transform 0.4s ${cssEasings.easeOutQuart};
    `}

  a[aria-current] {
    font-weight: bold;
  }

  & ${StyledContentExpander} {
    border-bottom: 1px solid ${colors.adaptedLightBlueBackground};
  }
`

StyledMobileMenu.Content = styled.div`
  overflow-y: auto;
  display: block;
  width: 100%;
  height: 100%;
  padding-bottom: ${spacing.xxxLarge};

  ::-webkit-scrollbar {
    display: none;
  }
`

StyledMobileMenu.InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`

StyledMobileMenu.SecondColumn = styled.div`
  margin-top: ${spacing.small};
`

StyledMobileMenu.Footer = styled.footer`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: ${spacing.xSmall} ${spacing.base};
  display: flex;
  align-items: center;
  background: ${colors.secondaryLightBlue};
  border-top: 1px solid ${colors.adaptedLightBlueBackground};
  & > * {
    margin-right: ${spacing.xSmall};
    display: inline-block;
  }
`

const buttonStyle = () => css`
  background: transparent;
  display: flex;
  align-items: center;
  color: ${colors.primaryDarkBlue};
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0.03em;
  padding: 0;
  padding: 24px 0;
  outline: none;
  border: none;
  border-bottom: ${props =>
    props.hascontent ? '' : `1px solid ${colors.adaptedLightBlueBackground}`};

  &:focus,
  &:active {
    outline: none;
  }

  &:not(:button, :a) {
    cursor: pointer;
  }
`

StyledMobileMenu.NavItem = styled.div`
  ${buttonStyle}

  & ${StyledIcon} {
    margin: 0 0 0 auto;
  }
`
StyledMobileMenu.NavItemButton = styled.button`
  ${buttonStyle}

  & ${StyledIcon} {
    margin: 0 0 0 auto;
    transform: ${props => (props.open ? 'rotate(180deg)' : '')};
    transition: transform .3s;
  }
`

StyledMobileMenu.NavItemInternal = styled(Link)`
  ${buttonStyle}
`

StyledMobileMenu.NavItemExternal = styled.a`
  ${buttonStyle}
`

StyledMobileMenu.SmallNavItemInternal = styled(Link)`
  ${buttonStyle}
  border: 0;
  font-size: ${typography.sizes.small};
  padding: ${spacing.small} 0;
`

StyledMobileMenu.SmallNavItemExternal = styled.a`
  ${buttonStyle}
  border: 0;
  font-size: ${typography.sizes.small};
  padding: ${spacing.small} 0;
`
export default StyledMobileMenu
