import styled, { css } from 'styled-components'
import { colors, mq, typography } from '../../styles/utilities/variables'

const StyledHamburger = styled.button`
  display: none;

  @media ${mq.mobile} {
    display: flex;
  }

  border: 0;
  color: ${props => (props.active ? colors.primaryDarkBlue : colors.white)};
  padding: 8px 10px;
  margin: 0 0 0 auto;
  cursor: pointer;
  z-index: 11;
  background: transparent;

  &:active,
  &:focus {
    outline: 0;
  }

  ${props =>
    props.active &&
    css`
      ${StyledHamburger.Line} {
        background: ${colors.primaryDarkBlue};
        &:nth-child(1) {
          transform: translateY(80%) rotate(45deg);
        }
        &:nth-child(2) {
          margin: 0;
          transform: translateY(-25%) rotate(-45deg);
        }
        &:nth-child(3) {
          opacity: 0;
        }
      }
    `}
`

StyledHamburger.Text = styled.span`
  font-size: 12px;
  line-heigth: 1;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-family: ${typography.secondaryFont};
  font-weight: bold;
`

StyledHamburger.Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 22px;
  padding-bottom: 1px;
`

StyledHamburger.Line = styled.div`
  height: 2px;
  width: 22px;
  background: ${colors.white};
  transition: margin 0.1s ease 0.1s, transform 0.2s ease 0.2s,
    opacity 0.1s ease 0.2s;

  &:nth-child(2) {
    margin: 3px 0;
  }
`

export default StyledHamburger
