import styled, { css } from 'styled-components'
import {
  typography,
  spacing,
  cssEasings,
} from '../../styles/utilities/variables'
import StyledContainer from '../../components/Container/style'

const StyledImage = styled.figure`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  align-items: ${props =>
    (props.align === 'right' && 'flex-end') ||
    (props.align === 'center' && 'center') ||
    'flex-start'};

  width: 100%;

  // set max-width if image is in styled container on /magazine
  [class*='StyledMagazineContainer'] ${StyledContainer} & {
    max-width: 650px;
  }

  ${props =>
    !props.isLoaded &&
    css`
      padding-bottom: 56.25%;
    `}

  ${props =>
    props.coverBackground &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `}

  img,
  picture {
    width: ${props => (props.width ? props.width : '100%')};
    max-height: 100%;

    ${props =>
      props.coverBackground &&
      css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      `}
  }

  [data-image] {
  }

  [data-placeholder] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: ${props => (props.isLoaded ? 0 : 1)};
    transition: opacity 0.15s ${cssEasings.easeOutSine};
    transition-delay: ${props => (props.isLoaded ? '.1s' : '0s')};
  }
`

StyledImage.Caption = styled.figcaption`
  font-size: ${typography.sizes.small};
  text-align: center;
  margin-top: ${spacing.small};
  width: ${props => props.width};
  ${props =>
    !props.isLoaded &&
    css`
      display: none;
    `}
`

StyledImage.Img = styled.img`
  opacity: ${props => (props.src ? 1 : 0)};
  transition: opacity 0.3s ${cssEasings.easeOutSine};
  object-fit: ${props => (props.objectFit ? props.objectFit : 'fill')};
`

export default StyledImage
