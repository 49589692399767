import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import StyledCartIcon from './style'
import { Icon } from '../Icon'

const CartIcon = props => {
  const { children, border, number, icon, handleClick } = props
  const [bounce, setBounce] = useState(false)

  useEffect(() => {
    setBounce(true)
    setTimeout(() => {
      setBounce(false)
    }, 200)
  }, [number])

  return (
    <StyledCartIcon onClick={handleClick} border={border}>
      <StyledCartIcon.IconWrapper>
        <Icon icon={`${icon ? icon : 'fa-shopping-cart'}`} size={22} />
        {number > 0 && (
          <StyledCartIcon.Number bounce={bounce}>
            {number}
          </StyledCartIcon.Number>
        )}
      </StyledCartIcon.IconWrapper>
      {children && <StyledCartIcon.Text>{children}</StyledCartIcon.Text>}
    </StyledCartIcon>
  )
}

CartIcon.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.string,
  number: PropTypes.number,
  border: PropTypes.bool,
  handleClick: PropTypes.func,
}

export { CartIcon }
