import { useEffect } from 'react'
let io
const listeners = new Map()
const getIntersectionObserver = options => {
  if (
    typeof io === `undefined` &&
    typeof window !== `undefined` &&
    'IntersectionObserver' in window
  ) {
    io = new window.IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (listeners.has(entry.target)) {
          const cb = listeners.get(entry.target)
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            io.unobserve(entry.target)
            listeners.delete(entry.target)
            cb()
          }
        }
      })
    }, options)
  }
  return io
}

/**
 * In view observer
 * @param {*} param0
 */
export const useIntersectionObserver = ({ target, onIntersect }) => {
  useEffect(() => {
    const observer = getIntersectionObserver({ rootMargin: '200px' })
    if (observer) {
      observer.observe(target.current)
      listeners.set(target.current, onIntersect)
      return () => {
        observer.unobserve(target.current)
        listeners.delete(target.current)
      }
    }
    onIntersect()
    return undefined
  }, [onIntersect, target])
}
