import React from 'react'
import PropTypes from 'prop-types'
import StyledMegaMenu from './style'
import { useMegaMenuQuery } from './hook'
import { Container } from '../../components/Container'
import { HasContent } from './Content'

const MegaMenu = ({ megaMenuActive, showSection }) => {
  const query = useMegaMenuQuery()
  return (
    <StyledMegaMenu active={megaMenuActive}>
      <Container>
        {query.map((item, key) => (
          <HasContent
            key={key}
            content={item.contentColumns}
            showContent={showSection === key}
          />
        ))}
      </Container>
    </StyledMegaMenu>
  )
}

MegaMenu.propTypes = {
  megaMenuActive: PropTypes.bool,
  showSection: PropTypes.number,
}

export default MegaMenu
