import styled from 'styled-components'
import {
  spacing,
  colors,
  typography,
} from '../../../styles/utilities/variables'
import StyledHeader from '../../../components/Heading/style'
import { Link } from 'gatsby'

const StyledHasContent = styled.div`
  display: ${props => (props.showContent ? 'block' : 'none')};

  & ${StyledHeader.h3} {
    color: ${colors.primaryDarkBlue};
    margin-bottom: ${spacing.small};
    text-transform: uppercase;
  }
`

StyledHasContent.List = styled.ul`
  list-style: none;
  border-top: 1px solid ${colors.adaptedLightBlueBackground};
  padding-top: ${spacing.small};
  columns: ${props => (props.wide ? '2' : '1')};

  & a {
    font-size: ${props =>
      props.small ? typography.sizes.small : typography.sizes.medium};
    line-height: ${props => (props.small ? '25px' : '27px')};
    letter-spacing: ${props => (props.small ? '' : '0.03em')};
    color: ${colors.primaryDarkBlue};
  }
`
StyledHasContent.ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${spacing.small};
  }
`

StyledHasContent.InternalLink = styled(Link)``
StyledHasContent.ExternalLink = styled.a``

export default StyledHasContent
