import { useStaticQuery, graphql } from 'gatsby'

export const useFooterQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    query FooterQuery($lang: WordPress_LanguageCodeFilterEnum) {
      wordpress {
        footerSettings(language: $lang) {
          data {
            footerColumns {
              columnTitle
              columnLinks {
                link {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const { footerColumns } = staticQuery.wordpress.footerSettings.data

  return { footerColumns: footerColumns }
}
