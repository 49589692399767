export const BASE_UNIT = 'rem'
export const BASE_VALUE = BASE_UNIT === 'px' ? 10 : 1

const calcValue = (value, divided = false) => {
  return divided ? BASE_VALUE / value : BASE_VALUE * value
}

/////////////////////////////////
// Colors
/////////////////////////////////
export const colors = {
  black: '#171717',
  white: '#FFFFFF',

  primaryDarkBlue: '#003145',
  primaryRed: '#FF5800',

  secondaryLightBlue: '#ADDBE3',
  secondaryYellow: '#FFBC3D',

  adaptedLightBlue: '#D3E3EA',
  adaptedLightBlueBackground: '#F5F9FA',

  warning: '#EB0000',
  invalid: '#cc0000',
  success: '#2CB091',
  grey: '#999999',
  lightGrey: '#E5E5E5',
  borderColor: '#ACBDC4',
  transparent: 'transparent'
}

export const boxShadow = '0px 0px 5px rgba(0, 0, 0, 0.3)'

/////////////////////////////////
// Spacing
/////////////////////////////////
export const spacing = {
  xxxLarge: `${calcValue(10)}${BASE_UNIT}`,
  xxLarge: `${calcValue(6.4)}${BASE_UNIT}`,
  xLarge: `${calcValue(4.8)}${BASE_UNIT}`,
  large: `${calcValue(3.6)}${BASE_UNIT}`,
  medium: `${calcValue(2.4)}${BASE_UNIT}`,
  base: `${calcValue(2)}${BASE_UNIT}`,
  small: `${calcValue(1.6)}${BASE_UNIT}`,
  xSmall: `${calcValue(0.8)}${BASE_UNIT}`,
  xxSmall: `${calcValue(0.4)}${BASE_UNIT}`,
  xxxSmall: `${calcValue(0.2)}${BASE_UNIT}`,
  none: `0`,
}

/////////////////////////////////
// Typography
/////////////////////////////////
export const typography = {
  primaryFont: 'Simplon Norm',
  secondaryFont: 'Simplon Mono, monospace',
  lineHeight: '1.5',
  sizes: {
    xxLarge: `${calcValue(9.6)}${BASE_UNIT}`,
    xLarge: `${calcValue(6)}${BASE_UNIT}`,
    large: `${calcValue(4.2)}${BASE_UNIT}`,
    medium: `${calcValue(2.4)}${BASE_UNIT}`,
    base: `${calcValue(1.8)}${BASE_UNIT}`,
    small: `${calcValue(1.6)}${BASE_UNIT}`,
    xSmall: `${calcValue(1.3)}${BASE_UNIT}`,
    xxSmall: `${calcValue(1.1)}${BASE_UNIT}`,
  },
}

export const radius = {
  small: `${calcValue(0.4)}${BASE_UNIT}`,
  medium: `${calcValue(0.8)}${BASE_UNIT}`,
  large: `${calcValue(3)}${BASE_UNIT}`,
  button: `${calcValue(1.2)}${BASE_UNIT}`,
}

export const maxWidths = {
  base: '650px',
  input: '350px',
  container: '1200px',
  wideContainer: '1200px',
  narrowContainer: '880px',
  narrowestContainer: '680px',
}

/////////////////////////////////
// Breakpoints
/////////////////////////////////
export const mqSizes = {
  large: 1440,
  tablet: 1024,
  tabletPortrait: 768,
  mobile: 767,
  mobileSmall: 480,
  smallHeight: 1000,
  custom: 1300,
}

export const mq = {
  custom: `(max-width: ${mqSizes.custom}px)`,
  tablet: `(max-width: ${mqSizes.tablet}px)`,
  mobile: `(max-width: ${mqSizes.mobile}px)`,
  mobileSmall: `(max-width: ${mqSizes.mobileSmall}px)`,
  tabletPortrait: `(max-width: 769px) and (min-width: 768px)`,
  specialHeadingRoom: `(max-width: 1357px)`,
  smallHeight: `(max-height: ${mqSizes.smallHeight}px)`,
}

export const displayNone = {
  mobile: `@media ${mq.mobile} { display: none; }`,
  tablet: `@media ${mq.tablet} { display: none; }`,
}

export const cssEasings = {
  // In
  easeInSine: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
  easeInQuad: 'cubic-bezier(0.550, 0.085, 0.680, 0.530)',
  easeInCubic: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)',
  easeInQuart: 'cubic-bezier(0.895, 0.030, 0.685, 0.220)',
  easeInQuint: 'cubic-bezier(0.755, 0.050, 0.855, 0.060)',
  easeInExpo: 'cubic-bezier(0.950, 0.050, 0.795, 0.035)',
  easeInCirc: 'cubic-bezier(0.600, 0.040, 0.980, 0.335)',
  easeInBack: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',

  // Out
  easeOutSine: 'cubic-bezier(0.390, 0.575, 0.565, 1.000)',
  easeOutQuad: 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
  easeOutCubic: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
  easeOutQuart: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
  easeOutQuint: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
  easeOutExpo: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  easeOutCirc: 'cubic-bezier(0.075, 0.820, 0.165, 1.000)',
  easeOutBack: 'cubic-bezier(0.175, 0.885, 0.320, 1.275)',

  // In-Out
  easeInOutSine: 'cubic-bezier(0.445, 0.050, 0.550, 0.950)',
  easeInOutQuad: 'cubic-bezier(0.455, 0.030, 0.515, 0.955)',
  easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
  easeInOutQuart: 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
  easeInOutQuint: 'cubic-bezier(0.860, 0.000, 0.070, 1.000)',
  easeInOutExpo: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)',
  easeInOutCirc: 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
  easeInOutBack: 'cubic-bezier(0.680, -0.550, 0.265, 1.550)',
}
