import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { darken } from 'polished'
import {
  colors,
  radius,
  typography,
  cssEasings,
  spacing,
} from '../../styles/utilities/variables'
import StyledIcon from '../Icon/style'

const hoverSpeed = 0.2

const getTextSize = size => {
  const sizes = {
    small: '1.4rem', // Special case
    medium: '1.4rem', // Special case,
    large: `${typography.sizes.small}`,
  }
  return sizes[size]
}

const getPadding = size => {
  const sizes = {
    small: `0.8rem 1rem`,
    medium: `1rem 1.2rem`,
    large: `1.6rem 1.8rem`,
  }
  return sizes[size]
}

const getLetterSpacing = size => {
  const sizes = {
    small: '0.1em',
    medium: '0.08em',
    large: '0.06em',
  }
  return sizes[size]
}

const buttonStyle = () => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props =>
    props.size ? getPadding(props.size) : getPadding('medium')};
  letter-spacing: ${props =>
    props.size ? getLetterSpacing(props.size) : getLetterSpacing('medium')};
  font-size: ${props =>
    props.size ? getTextSize(props.size) : getTextSize('medium')};
  font-family: ${typography.secondaryFont};
  font-weight: 500;
  text-align: center;
  border-radius: ${radius.button};
  border: none;
  color: ${props => (props.textcolor ? colors[props.textcolor] : colors.white)};
  border: solid 1px
    ${props => (props.color ? colors[props.color] : colors.primaryDarkBlue)};
  background-color: ${props =>
    props.color ? colors[props.color] : colors.primaryDarkBlue};
  font-weight: 500;
  transition: background-color ${hoverSpeed}s ${cssEasings.easeOutCubic},
    color ${hoverSpeed}s ${cssEasings.easeOutCubic},
    border-color ${hoverSpeed}s ${cssEasings.easeOutCubic},
    transform 0.1s ${cssEasings.easeInOutCubic};
  // font-size: ${typography.sizes.small};
  overflow: hidden;
  text-transform: uppercase;
  width: ${props => (props.fullwidth ? '100%' : 'auto')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'none')};
  transition: opacity .2s;

  &:active,
  &:focus {
    /* transform:scale(0.95); */
    outline: none;
  }

  ${StyledIcon} {
    height: ${typography.sizes.small};
    padding: ${props =>
      props.iconbefore ? `0 ${spacing.xSmall} 0 0` : `0 0 0 ${spacing.xSmall}`};
    i {
      line-height: ${typography.sizes.small};
    }
  }
  

  &:hover {
    background-color: ${props =>
      props.color
        ? darken(0.1, colors[props.color])
        : darken(0.1, colors.primaryDarkBlue)};
    border-color: ${props =>
      props.color
        ? darken(0.1, colors[props.color])
        : darken(0.1, colors.primaryDarkBlue)};

        ${props =>
          props.disabled &&
          css`
            border-color: ${colors.primaryDarkBlue};
            background-color: ${colors.primaryDarkBlue};
          `}
  }

  ${props =>
    props.outline &&
    css`
      background: ${props =>
        (props.active && !props.color && colors.primaryDarkBlue) ||
        (props.active && colors[props.color]) ||
        'transparent'};

      color: ${props =>
        (props.active && colors.white) ||
        (!props.active && props.color && colors[props.color]) ||
        colors.primaryDarkBlue};

      border-color: ${props =>
        props.color ? colors[props.color] : colors.primaryDarkBlue};
      &:hover {
        color: ${colors.white};
        background-color: ${props =>
          props.color ? colors[props.color] : colors.primaryDarkBlue};
        border-color: ${props =>
          props.color ? colors[props.color] : colors.primaryDarkBlue};
      }
    `}

    ${props =>
      props.transparent &&
      css`
        background: transparent;
        color: ${props =>
          props.color ? colors[props.color] : colors.primaryDarkBlue};
        border-color: transparent;
        &:hover {
          background: transparent;
          border-color: transparent;
          color: ${props =>
            props.color
              ? darken(0.1, colors[props.color])
              : darken(0.1, colors.primaryDarkBlue)};
        }
      `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      /* display: flex;
      text-transform: uppercase;
      padding-left: 2rem !important;
      color: ${colors.secondaryLightBlue};
      font-size: 1.3rem;

      div {
        padding-left: 1rem !important;
      } */
    `}
`

export const StyledButton = styled.button`
  ${buttonStyle};

  & svg {
    margin: 0 ${spacing.xSmall} 0px ${spacing.xxSmall};
  }

  ${props =>
    props.svg &&
    css`
      span {
        margin-top: 1px;
      }
    `}
`

StyledButton.Internal = styled(Link)`
  ${buttonStyle};
  display: inline-flex;
`

StyledButton.External = styled.a`
  ${buttonStyle};
  display: inline-flex;
`

export default StyledButton
