import React, { useState, useContext } from 'react'
import { AppContext, openCart, setUser } from '../../config/context'
import { getUrl, shouldItemBeActive } from '../../helpers/url'
import StyledHeader from './style'
import { useHeaderQuery } from './hook'
import { useHasMounted } from '../../hooks/useHasMounted'

import { Row } from '../../components/Row'
import { Container } from '../../components/Container'
import { Icon } from '../../components/Icon'
import { Logo } from '../../components/Svg'
import { Overlay } from '../../components/Overlay'
import Hamburger from '../../components/Hamburger'
import { OverflowHiddenStyle } from '../../styles/base/overflowHiddenStyle'

import MobileMenu from '../MobileMenu'
import MegaMenu from '../MegaMenu'
import { LanguageSwitcher } from '../../components/LanguageSwitcher'
import { ProfileSwitcher } from '../../components/ProfileSwitcher'

import { CartIcon } from '../../components/CartIcon'
import { purchaseEnabled } from '../../helpers/checkout'
import { logout } from '../../services/auth'
import { navigate } from 'gatsby'
import { colors } from '../../styles/utilities/variables'
const Header = () => {
  const [state, dispatch] = useContext(AppContext) // eslint-disable-line
  const { productsInCart, user } = state
  const headerSettings = useHeaderQuery()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [activeSection, setActiveSection] = useState(Number)

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const [megaMenuActive, setMegaMenuActive] = useState(false)
  const toggleMegaMenu = () => {
    setMegaMenuActive(!megaMenuActive)
  }

  const openCartFlyout = () => {
    dispatch(openCart())
  }

  const [languageSwitcherOpen, setLanguageSwitcherOpen] = useState(false)
  const toggleLanguageSwitcher = () => {
    setLanguageSwitcherOpen(!languageSwitcherOpen)
  }

  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen)
  }

  const hasMounted = useHasMounted()
  const menuItemIsActive = item => {
    return hasMounted && shouldItemBeActive(item) ? 1 : 0
  }

  const navigateToLogin = () => {
    if (typeof window !== 'undefined') {
      navigate('/login')
    }
  }

  const handleLogout = e => {
    e.preventDefault()
    dispatch(setUser([]))
    logout(navigateToLogin)
  }

  const loggedUser =
    typeof user == 'object' && Object.keys(user).length ? true : false

  const loggedUserMenu = [
    { title: 'My orders', url: '/account', action: false },
    { title: 'Account', url: '/profile', action: false },
    { title: 'Logout', url: '#', action: handleLogout },
  ]

  const nonLoggedUser = [
    { title: 'Register', url: '/register', action: false },
    { title: 'Login', url: '/login', action: false },
  ]

  return (
    <>
      <StyledHeader id='header'>
        <Container>
          <Row>
            <StyledHeader.Logo to={'/'}>
              <Logo />
            </StyledHeader.Logo>

            <StyledHeader.MobileItems>
              {purchaseEnabled && (
                <NavItem onClick={openCartFlyout}>
                  <CartIcon
                    number={productsInCart.length}
                    icon='shopping_cart'
                  />
                </NavItem>
              )}

              <NavItem onClick={toggleUserMenu}>
                {user && Object.keys(user).length ? (
                  <>
                    <Icon size={22} icon='person' color={colors.primaryRed} />
                    <ProfileSwitcher
                      links={loggedUserMenu}
                      open={userMenuOpen}
                      setOpen={setUserMenuOpen}
                    />
                  </>
                ) : (
                  <>
                    {' '}
                    <Icon size={22} icon='person' />
                    <ProfileSwitcher
                      links={nonLoggedUser}
                      open={userMenuOpen}
                      setOpen={setUserMenuOpen}
                    />
                  </>
                )}
              </NavItem>
              <Hamburger
                text={null}
                active={mobileMenuOpen}
                handleClick={() => {
                  setMobileMenuOpen(!mobileMenuOpen)
                }}
              />
            </StyledHeader.MobileItems>

            <StyledHeader.PrimaryNav>
              {headerSettings.mainMenu &&
                headerSettings.mainMenu.map((item, key) => {
                  return (
                    <NavItem
                      key={key}
                      onClick={item.type === 'content' ? toggleMegaMenu : null}
                      to={
                        item.type === 'link' && item.link
                          ? getUrl(item.link.url)
                          : null
                      }
                      onMouseEnter={() => {
                        if (item.type === 'content') setActiveSection(key)
                      }}
                      active={menuItemIsActive(item)}
                    >
                      <span>{item.label}</span>
                    </NavItem>
                  )
                })}
            </StyledHeader.PrimaryNav>
            <StyledHeader.SecondaryNav>
              <NavItem onClick={toggleLanguageSwitcher}>
                <Icon size={22} icon='language' />
                <LanguageSwitcher
                  links={headerSettings.languageSwitcher}
                  open={languageSwitcherOpen}
                  setOpen={setLanguageSwitcherOpen}
                />
              </NavItem>

              {/* My pages */}

              <NavItem onClick={toggleUserMenu}>
                {user && Object.keys(user).length ? (
                  <>
                    <Icon size={22} icon='person' color={colors.primaryRed} />
                    <ProfileSwitcher
                      links={loggedUserMenu}
                      open={userMenuOpen}
                      setOpen={setUserMenuOpen}
                    />
                  </>
                ) : (
                  <>
                    {' '}
                    <Icon size={22} icon='person' />
                    <ProfileSwitcher
                      links={nonLoggedUser}
                      open={userMenuOpen}
                      setOpen={setUserMenuOpen}
                    />
                  </>
                )}
              </NavItem>

              {/* Cart trigger */}
              <NavItem onClick={openCartFlyout}>
                <CartIcon number={productsInCart.length} icon='shopping_cart' />
              </NavItem>
            </StyledHeader.SecondaryNav>
          </Row>
        </Container>

        <MobileMenu
          open={mobileMenuOpen}
          menuItems={headerSettings.mainMenu}
          handleClose={handleMobileMenuToggle}
          languages={headerSettings.languageSwitcher}
        />
      </StyledHeader>
      <MegaMenu megaMenuActive={megaMenuActive} showSection={activeSection} />
      {megaMenuActive && <Overlay handleClick={toggleMegaMenu} />}
      {megaMenuActive && <OverflowHiddenStyle />}
    </>
  )
}

const NavItem = props => {
  const { children, onClick, to, active } = props
  const Component = to ? StyledHeader.NavItemLink : StyledHeader.NavItemButton
  return onClick || to ? (
    <Component {...props}>{children}</Component>
  ) : (
    <StyledHeader.NavItem active={active ? 1 : 0}>
      {children}
    </StyledHeader.NavItem>
  )
}

export default Header
