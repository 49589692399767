import React from 'react'
import PropTypes from 'prop-types'
import StyledOverlay from './style'

const Overlay = ({ children, handleClick }) => {
  return <StyledOverlay onClick={handleClick}>{children}</StyledOverlay>
}

Overlay.propTypes = {
  children: PropTypes.any,
  handleClick: PropTypes.func,
}

export { Overlay }
