/**
 * Get right products in cart
 * return format on each product:
 * id
 * name
 * sku
 * price
 * image
 */

import { CUSTOMER_CURRENCY } from '../../node/functions'
import { PRODUCT_TYPES } from './constants'
import { gtag } from './google'

export const getProductsInCart = (allProducts, productsInCart) => {
  return allProducts.reduce((acc, product) => {
    const { type, variations } = product

    let matchingProducts = []

    if (
      type === PRODUCT_TYPES.VARIABLE &&
      variations.nodes &&
      variations.nodes.length
    ) {
      variations.nodes.forEach(node => {
        if (node && node.variationId) {
          let existsInCart = productsInCart.reduce((acc, product) => {
            return product.id === node.variationId ? true : acc
          }, false)

          let quantity = productsInCart.reduce((acc, product) => {
            return product.id === node.variationId ? product.quantity : acc
          }, 1)

          if (existsInCart) {
            matchingProducts.push({
              id: node.variationId,
              name: node.name,
              sku: node.sku,
              price: node.finalPrice,
              image: node.image,
              quantity: quantity,
            })
          }
        }
      })
    }

    if (productsInCart.includes(product.productId)) {
      matchingProducts.push({
        id: product.productId,
        name: product.name,
        sku: product.sku,
        price: product.price,
        image: product.image,
      })
    }

    return matchingProducts.length > 0 ? [...acc, ...matchingProducts] : acc
  }, [])
}

/**
 * Purchase enabled
 */
export const purchaseEnabled =
  process.env.GATSBY_PURCHASE_ENABLED !== undefined
    ? process.env.GATSBY_PURCHASE_ENABLED === 'true'
    : true

export const renderKlarnaSnippet = (elementId, { html_snippet, ecommerce }) => {
  if (typeof document === 'undefined') {
    return
  }

  var element = document.getElementById(elementId)
  element.innerHTML = html_snippet

  var scriptsTags = element.getElementsByTagName('script')
  // This is necessary otherwise the scripts tags are not going to be evaluated
  for (var i = 0; i < scriptsTags.length; i++) {
    var parentNode = scriptsTags[i].parentNode
    var newScriptTag = document.createElement('script')
    newScriptTag.type = 'text/javascript'
    newScriptTag.text = scriptsTags[i].text
    parentNode.removeChild(scriptsTags[i])
    parentNode.appendChild(newScriptTag)
  }

  window._klarnaCheckout(function(api) {
    // https://docs.klarna.com/klarna-checkout/in-depth-knowledge/analytics/#what-do-the-events-mean-from-an-analytics-point-of-view?
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm
    api.on({ redirect_initiated: () => gtag('purchase', { ecommerce }) })
  })
}

export const mapCartToGaEcommerce = products => {
  const items = products.map(product => ({
    item_id: product.sku,
    item_name: product.name,
    price: Number(product.price),
    quantity: Number(product.quantity),
  }))

  return {
    value: items.reduce((total, item) => total + item.price * item.quantity, 0),
    currency: CUSTOMER_CURRENCY,
    items,
  }
}
