import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import {
  spacing,
  mq,
  colors,
  typography,
  displayNone,
} from '../../styles/utilities/variables'
import StyledIcon from '../../components/Icon/style'

export const headerHeights = {
  desktop: '72px',
  mobile: '72px',
}

const StyledHeader = styled.div`
  background: ${colors.primaryDarkBlue};
  z-index: 1005;
  height: ${headerHeights.desktop};
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;

  @media ${mq.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${headerHeights.mobile};
  }
`

StyledHeader.Logo = styled(Link)`
  font-size: 0;
  margin-right: ${spacing.small};
  z-index: 5;
`

StyledHeader.MobileItems = styled.nav`
  display: none;

  @media ${mq.mobile} {
    display: flex;
  }
`

StyledHeader.PrimaryNav = styled.nav`
  ${displayNone.mobile}
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

StyledHeader.SecondaryNav = styled.nav`
  ${displayNone.mobile}
  display: flex;

  & i {
    color: ${colors.white};
  }
  & div {
    &:not(:last-child) {
      margin-right: ${spacing.small};
    }
  }
`

StyledHeader.Toggle = styled.div`
  display: none;
  @media ${mq.mobile} {
    display: block;
  }
`

const buttonStyle = () => css`
  display: flex;
  align-items: center;
  font-family: ${typography.secondaryFont};
  font-size: ${typography.sizes.small};
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: ${props => (props.active ? colors.secondaryLightBlue : colors.white)};
  margin: 0 ${spacing.small};
  padding: 0;
  border: none;
  outline: none;
  background: transparent;

  &:focus,
  &:active {
    outline: none;
  }

  a&:hover,
  button&:hover {
    ${StyledIcon}, span {
      opacity: 0.6;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:not(:button, :a) {
    cursor: pointer;
  }

  &:[aria-current] {
    color: #addbe3;
  }

  ${StyledIcon} {
    margin: 0 !important;
  }
`

StyledHeader.NavItem = styled.div`
  ${buttonStyle}
  position: relative;
`
StyledHeader.NavItemButton = styled.button`
  ${buttonStyle}
  position: relative;
`
StyledHeader.NavItemLink = styled(Link)`
  ${buttonStyle}
  position: relative;
`

export default StyledHeader
