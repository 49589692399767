exports.enStrings = [
  {
    name: 'PICKUP',
    value: 'PICK UP',
    fieldGroupName: 'string_list',
  },
  {
    name: 'vat',
    value: 'Inc vat',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Account Page',
    value: 'Account Page',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Register',
    value: 'Register',
    fieldGroupName: 'string_list',
  },
  {
    name: 'My orders',
    value: 'My orders',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Account',
    value: 'Account',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Logout',
    value: 'Log out',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Date',
    value: 'Date',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Status',
    value: 'Status',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Hi',
    value: 'Hi',
    fieldGroupName: 'string_list',
  },
  {
    name: 'loading...',
    value: 'loading...',
    fieldGroupName: 'string_list',
  },
  {
    name: 'no orders',
    value: 'no orders',
    fieldGroupName: 'string_list',
  },
  {
    name: 'more info',
    value: 'more info',
    fieldGroupName: 'string_list',
  },
  {
    name: 'BILLING',
    value: 'BILLING',
    fieldGroupName: 'string_list',
  },
  {
    name: 'SHIPPING',
    value: 'SHIPPING',
    fieldGroupName: 'string_list',
  },
  {
    name: 'VAT',
    value: 'vat',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Total incl VAT',
    value: 'Total incl vat',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Payment method',
    value: 'Payment method',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Profile',
    value: 'Profile',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Update',
    value: 'Updated',
    fieldGroupName: 'string_list',
  },
  {
    name: 'username',
    value: 'Username',
    fieldGroupName: 'string_list',
  },
  {
    name: 'email',
    value: 'e-mail',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Profile Page',
    value: 'Profile',
    fieldGroupName: 'string_list',
  },
  {
    name: 'order number',
    value: 'order number',
    fieldGroupName: 'string_list',
  },
  {
    name: 'password',
    value: 'password',
    fieldGroupName: 'string_list',
  },
  {
    name: 'repeat password',
    value: 'repeat password',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Search',
    value: 'Search',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Have an account ? Login here',
    value: 'Have an account ? Login here',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Reset password',
    value: 'Reset password',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Reset link',
    value: 'Password reset link',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Login instead',
    value: 'Log in instead',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Sing in',
    value: 'Login',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Login',
    value: 'Login',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Forgot password?',
    value: 'Forgot password?',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Update password',
    value: 'Update password',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Order',
    value: 'Order',
    fieldGroupName: 'string_list',
  },
  {
    name: 'product',
    value: 'Product',
    fieldGroupName: 'string_list',
  },
  {
    name: 'artice number',
    value: 'Article number',
    fieldGroupName: 'string_list',
  },
  {
    name: 'quantity',
    value: 'qty',
    fieldGroupName: 'string_list',
  },
  {
    name: 'paid',
    value: 'Paid',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Updated!',
    value: 'Updated!',
    fieldGroupName: 'string_list',
  },
  {
    name: 'article number',
    value: 'article number',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Order',
    value: 'Order',
    fieldGroupName: 'string_list',
  },
  {
    name: 'logging in',
    value: 'login',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Register',
    value: 'Register',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Account',
    value: 'Profile',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Logout',
    value: 'Logout',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Login',
    value: 'Login',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Back',
    value: 'Back',
    fieldGroupName: 'string_list',
  },
  {
    name: 'ORDER',
    value: 'ORDER',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Date',
    value: 'Date',
    fieldGroupName: 'string_list',
  },
  {
    name: 'loginPage',
    value: 'Login Page',
    fieldGroupName: 'string_list',
  },
  {
    name: 'completed',
    value: 'completed',
    fieldGroupName: 'string_list',
  },
  {
    name: 'processing',
    value: 'processing',
    fieldGroupName: 'string_list',
  },
  {
    name: 'pending payment',
    value: 'pending payment',
    fieldGroupName: 'string_list',
  },
  {
    name: 'on hold',
    value: 'on hold',
    fieldGroupName: 'string_list',
  },
  {
    name: 'cancel',
    value: 'canceled',
    fieldGroupName: 'string_list',
  },
  {
    name: 'refunded',
    value: 'refunded',
    fieldGroupName: 'string_list',
  },
  {
    name: 'Edit profile',
    value: 'Edit profile',
    fieldGroupName: 'string_list',
  },
  {
    name: 'cancelled',
    value: 'cancelled',
    fieldGroupName: 'string_list',
  },
  {
    name: 'completed',
    value: 'completed',
    fieldGroupName: 'string_list',
  },
  {
    name: 'failed',
    value: 'failed',
    fieldGroupName: 'string_list',
  },
  {
    name: 'processing',
    value: 'processing',
    fieldGroupName: 'string_list',
  },
  {
    name: 'on hold',
    value: 'on hold',
    fieldGroupName: 'string_list',
  },
  {
    name: 'refunded',
    value: 'refunded',
    fieldGroupName: 'string_list',
  },
  {
    name: 'pending',
    value: 'pending',
    fieldGroupName: 'string_list',
  },
  {
    name: 'no info',
    value: '-',
    fieldGroupName: 'string_list',
  },
  {
    name: 'directionsText',
    value: 'Route directions',
    fieldGroupName: 'string_list',
  },
]
