import styled from 'styled-components'
import { mq } from '../../styles/utilities/variables'

const StyledCol = styled.div`

  ${({ column }) =>
    column &&
    `
      grid-column: ${column};
  `}

  ${({ row }) =>
    row &&
    `
      grid-row: ${row};
  `}

  ${({ justify }) =>
    justify &&
    `
      justify-self: ${justify};
  `}

  ${({ align }) =>
    align &&
    `
    align-self: ${align};
  `}

  @media ${mq.mobile} {
    grid-column: auto;
  }
`

export default StyledCol
