import React, { useRef, useEffect, useState } from 'react'
import { useClickOutside } from '../../hooks/useClickOutside'
import { useHasMounted } from '../../hooks/useHasMounted'
import StyledProfileSwitcher from './style'
import { useTranslation } from '../../hooks/useTranslation'

const ProfileSwitcher = props => {
  const { links, open, setOpen } = props

  const hasMounted = useHasMounted()
  const [currentDomain, setCurrentDomain] = useState(null)

  const getTranslation = useTranslation()
  const switcherRef = useRef(null)

  useClickOutside(switcherRef, () => {
    if (open) setOpen(false)
  })

  useEffect(() => {
    if (hasMounted) setCurrentDomain(window.location.hostname)
  }, [hasMounted])

  return (
    <StyledProfileSwitcher ref={switcherRef} open={open}>
      <StyledProfileSwitcher.List>
        {links &&
          links.length > 0 &&
          links.map((item, index) => {
            const { title, url, action } = item
            return (
              <StyledProfileSwitcher.ListItem
                key={index}
                active={currentDomain && url.includes(currentDomain)}
              >
                {action ? (
                  <a href={url} onClick={action}>
                    {getTranslation(title)}
                  </a>
                ) : (
                  <a href={url}>{getTranslation(title)}</a>
                )}
              </StyledProfileSwitcher.ListItem>
            )
          })}
      </StyledProfileSwitcher.List>
    </StyledProfileSwitcher>
  )
}

export { ProfileSwitcher }
