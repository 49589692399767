import styled from 'styled-components'

const StyledOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
`

export default StyledOverlay
