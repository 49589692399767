export const COLORS = {
  BLACK: 'black',
  WHITE: 'white',

  PRIMARY_DARK_BLUE: 'primaryDarkBlue',
  PRIMARY_RED: 'primaryRed',
  SECONDARY_LIGHT_BLUE: 'secondaryLightBlue',
  SECONDARY_YELLOW: 'secondaryYellow',
  ADAPTED_LIGHT_BLUE: 'adaptedLightBlue',
  ADAPTED_LIGHT_BLUE_BACKGROUND: 'adaptedLightBlueBackground',

  WARNING: 'warning',
  INVALID: 'invalid',
  SUCCESS: 'success',
  GREY: 'grey',
  LIGHT_GREY: 'lightGrey',
  BORDER_COLOR: 'borderColor',
}

export const SPACING = {
  XXX_LARGE: 'xxxLarge',
  XX_LARGE: 'xxLarge',
  X_LARGE: 'xLarge',
  LARGE: 'large',
  MEDIUM: 'medium',
  BASE: 'base',
  SMALL: 'small',
  X_SMALL: 'xSmall',
  XX_SMALL: 'xxSmall',
  XXX_SMALL: 'xxxSmall',
  NONE: 'none',
}

export const TEXT_SIZES = {
  XX_LARGE: 'xxLarge',
  X_LARGE: 'xLarge',
  LARGE: 'large',
  MEDIUM: 'medium',
  BASE: 'base',
  SMALL: 'small',
  X_SMALL: 'xSmall',
  XX_SMALL: 'xxSmall',
}

export const DEALER_TYPES = {
  ALL: 'all',
  STORE: 'store',
  WORKSHOP: 'workshop',
}

export const PRODUCT_TYPES = {
  SIMPLE: 'SIMPLE',
  VARIABLE: 'VARIABLE',
}

export const PRIMARY_MENU = {
  OUR_ELECTRIC_BIKES: 'our_electric_bikes',
  SHOPS_AND_WORKSHOPS: 'shops_and_workshops',
  SUPPORT: 'support',
  ABOUT_US: 'about_us',
  MAGAZINE: 'magazine',
}

export const MAGAZINE_MENU = {
  BUYING_GUIDE: 'buying_guide',
  INSIGHT: 'insight',
  USING: 'using',
}
