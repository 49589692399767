import React from 'react'
import StyledCookieBar from './style'
import { Container } from '../Container'
import { useState } from 'react'
import { getCookie, setCookie } from '../../helpers/storage'
import { useEffect } from 'react'
import { Text } from '../Text'
import { Button } from '../Button'
import { getUrl } from '../../helpers/url'

const CookieBar = ({ message, consent, readMore }) => {
  const COOKIE = 'closed_cookie_consent'
  const [visible, setVisible] = useState(false)
  const handleClick = () => {
    setVisible(false)
    setCookie(COOKIE, true, 30)
  }

  useEffect(() => {
    if (!visible && !getCookie(COOKIE)) setVisible(true)
  }, [])

  return (
    <StyledCookieBar visible={visible}>
      <Container>
        <StyledCookieBar.Inner>
          <Text center>{message}</Text>
          <StyledCookieBar.Buttons>
            <Button handleClick={handleClick}>{consent}</Button>
            {readMore && (
              <Button href={getUrl(readMore.url)} secondary>
                {readMore.title}
              </Button>
            )}
          </StyledCookieBar.Buttons>
        </StyledCookieBar.Inner>
      </Container>
    </StyledCookieBar>
  )
}

export { CookieBar }
