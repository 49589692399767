import React from 'react'
import PropTypes from 'prop-types'
import StyledIcon from './style'

const Icon = ({ icon, size = 24, handleClick, disabled, color }) => {
  return (
    <StyledIcon
      onClick={handleClick}
      size={size}
      color={color}
      disabled={disabled}
    >
      <i className='material-icons'>{icon}</i>
    </StyledIcon>
  )
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  handleClick: PropTypes.func,
}

export { Icon }
