import { useStaticQuery, graphql } from 'gatsby'
import { svStrings } from '../helpers/translationsSv'
import { enStrings } from '../helpers/translationsEn'
import { noStrings } from '../helpers/translationsNo'

const lang = process.env.GATSBY_LANG ? process.env.GATSBY_LANG : 'SV'

const useTranslation = () => {
  const staticQuery = useStaticQuery(graphql`
    query useTranslationQuery($lang: WordPress_LanguageCodeFilterEnum) {
      wordpress {
        stringTranslationsSettings(language: $lang) {
          data {
            stringList {
              name
              value
            }
          }
        }
      }
    }
  `)

  let { stringList } = staticQuery.wordpress.stringTranslationsSettings.data
  if (lang === 'SV') {
    stringList = stringList.concat(svStrings)
  } else if (lang === 'NO') {
    stringList = stringList.concat(noStrings)
  } else {
    stringList = stringList.concat(enStrings)
  }

  const getTranslation = string => {
    return string
      ? stringList.reduce((acc, item) => {
          if (item.name === string) {
            return item.value
          }
          return acc
        }, string)
      : ''
  }

  return getTranslation
}

export { useTranslation }
