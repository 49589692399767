exports.noStrings = [
  {
    name: 'PICKUP',
    value: 'LEVERINGSSTED',
  },
  {
    name: 'vat',
    value: 'Inkl mva',
  },
  {
    name: 'Account Page',
    value: 'Mine sider',
  },
  {
    name: 'Register',
    value: 'Registrer',
  },
  {
    name: 'My orders',
    value: 'Mine bestillinger',
  },
  {
    name: 'Account',
    value: 'Konto',
  },
  {
    name: 'Logout',
    value: 'Logg ut',
  },
  {
    name: 'Date',
    value: 'Dato',
  },
  {
    name: 'Status',
    value: 'Status',
  },
  {
    name: 'Hi',
    value: 'Hei',
  },
  {
    name: 'loading...',
    value: 'leser ...',
  },
  {
    name: 'no orders',
    value: 'ingen bestillinger',
  },
  {
    name: 'more info',
    value: 'Mer informasjon',
  },
  {
    name: 'BILLING',
    value: 'FAKTURERING',
  },
  {
    name: 'SHIPPING',
    value: 'FRAKT',
  },
  {
    name: 'VAT',
    value: 'Moms',
  },
  {
    name: 'Total incl VAT',
    value: 'Total inkl. mva.',
  },
  {
    name: 'Payment method',
    value: 'Betalingsmåte',
  },
  {
    name: 'Profile',
    value: 'Profil',
  },
  {
    name: 'Update',
    value: 'Oppdater',
  },
  {
    name: 'username',
    value: 'Brukernavn',
  },
  {
    name: 'email',
    value: 'e-post',
  },
  {
    name: 'Profile Page',
    value: 'Konto',
  },
  {
    name: 'order number',
    value: 'ordrenummer',
  },
  {
    name: 'password',
    value: 'Passord',
  },
  {
    name: 'repeat password',
    value: 'gjenta passord',
  },
  {
    name: 'Search',
    value: 'Søk',
  },
  {
    name: 'Have an account ? Login here',
    value: 'Har du en konto? Logg deg på',
  },
  {
    name: 'Reset password',
    value: 'Tilbakestill passord',
  },
  {
    name: 'Reset link',
    value: 'Tilbakestill link',
  },
  {
    name: 'Login instead',
    value: 'Logg på i stedet',
  },
  {
    name: 'Sing in',
    value: 'Logg inn',
  },
  {
    name: 'Login',
    value: 'Logg inn',
  },
  {
    name: 'Forgot password?',
    value: 'Glemt passordet ditt?',
  },
  {
    name: 'Update password',
    value: 'Oppdater passord',
  },
  {
    name: 'Order',
    value: 'Kjøp',
  },
  {
    name: 'product',
    value: 'Produkt',
  },
  {
    name: 'artice number',
    value: 'Artikkelnummer',
  },
  {
    name: 'quantity',
    name: 'antall',
    value: 'sku',
  },
  {
    name: 'paid',
    value: 'Sum',
  },
  {
    name: 'Updated!',
    value: 'Oppdatert!',
  },
  {
    name: 'article number',
    value: 'artikkelnummer',
  },
  {
    name: 'Order',
    value: 'Kjøp',
  },
  {
    name: 'logging in',
    value: 'logger på',
  },
  {
    name: 'Register',
    value: 'Registrer',
  },
  {
    name: 'Account',
    value: 'Konto',
  },
  {
    name: 'Logout',
    value: 'Logg ut',
  },
  {
    name: 'Register',
    value: 'Registrer',
  },
  {
    name: 'Login',
    value: 'Logg inn',
  },
  {
    name: 'Back',
    value: 'Tilbake',
  },
  {
    name: 'ORDER',
    value: 'ORDER',
  },
  {
    name: 'Date',
    value: 'Dato',
  },
  {
    name: 'loginPage',
    value: 'Påloggingsside',
  },
  {
    name: 'completed',
    value: 'fullført',
  },
  {
    name: 'processing',
    value: 'behandling',
  },
  {
    name: 'pending payment',
    value: 'venter på betaling',
  },
  {
    name: 'on hold',
    value: 'venter',
  },
  {
    name: 'cancel',
    value: 'avbrutt',
  },
  {
    name: 'refunded',
    value: 'refunderbart',
  },
  {
    name: 'Edit profile',
    value: 'Rediger profil',
  },
  {
    name: 'cancelled',
    value: 'avbrutt',
  },
  {
    name: 'completed',
    value: 'fullført',
  },
  {
    name: 'failed',
    value: 'mislyktes',
  },
  {
    name: 'processing',
    value: 'behandling',
  },
  {
    name: 'on hold',
    value: 'plassert',
  },
  {
    name: 'refunded',
    value: 'Refunderbart',
  },
  {
    name: 'pending',
    value: 'Venter',
  },
  {
    name: 'no info',
    value: '-',
  },
]
