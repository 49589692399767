import React, { useContext, useState } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import {
  AppContext,
  removeProductFromCompare,
  disableCompare,
} from '../../config/context'
import StyledCompareBar from './style'
import { CompareCard } from '../CompareCard'
import { Container } from '../Container'
import { Button } from '../Button'
import { Text } from '../Text'
import noImageFallback from '../../assets/images/noImageFallback.png'
import { COLORS, TEXT_SIZES } from '../../helpers/constants'
import { ContentExpander } from '../ContentExpander'
import { useResponsive } from '../../hooks/useResponsive'
import { mqSizes } from '../../styles/utilities/variables'
import { useTranslation } from '../../hooks/useTranslation'
import { Icon } from '../Icon'

const CompareBar = props => {
  const [state, dispatch] = useContext(AppContext)
  const { products, compareIsEnabled, productsToCompare } = state

  const getTranslation = useTranslation()

  const { windowSize } = useResponsive()
  const [bodyOpen, setBodyOpen] = useState(
    windowSize.height > mqSizes.smallHeight
  )

  const filteredProducts = products.filter(product =>
    productsToCompare.includes(product.productId)
  )

  const emptySlots = Math.max(0, 4 - productsToCompare.length)

  const { heading, href } = props

  const dynamicHeading =
    productsToCompare.length > 0
      ? `${productsToCompare.length} ${getTranslation(
          'compareInformationMaxText'
        )}`
      : heading

  const handleToCompareClick = () => {
    navigate(href)
    setTimeout(() => {
      dispatch(disableCompare())
    }, 200)
  }

  return (
    <StyledCompareBar open={compareIsEnabled}>
      <Container narrow>
        <StyledCompareBar.Top>
          <StyledCompareBar.Heading
            onClick={() => {
              setBodyOpen(!bodyOpen)
            }}
          >
            {dynamicHeading}
          </StyledCompareBar.Heading>
          <StyledCompareBar.TopControls>
            <Button
              icon={'arrow_forward'}
              disabled={productsToCompare.length < 2}
              textColor={COLORS.SECONDARY_LIGHT_BLUE}
              handleClick={handleToCompareClick}
            >
              {getTranslation('compareShowComparison')}
            </Button>

            <StyledCompareBar.CloseButton
              onClick={() => {
                dispatch(disableCompare())
              }}
            >
              <Text size={TEXT_SIZES.SMALL}>
                {getTranslation('compareClose')}
              </Text>
              <Icon size={26} icon={'clear'} />
            </StyledCompareBar.CloseButton>
          </StyledCompareBar.TopControls>
        </StyledCompareBar.Top>
        <ContentExpander open={bodyOpen} duration={0.25}>
          <StyledCompareBar.Body>
            {filteredProducts.map((product, index) => {
              const { id, productId, image, name } = product

              const productImage =
                image && image.sourceUrl ? image.sourceUrl : noImageFallback

              return (
                <CompareCard
                  key={id}
                  id={id}
                  name={name}
                  image={productImage}
                  handleRemoveClick={() => {
                    dispatch(removeProductFromCompare(productId))
                  }}
                />
              )
            })}

            {[...Array(emptySlots)].map((x, index) => (
              <CompareCard key={index} />
            ))}
          </StyledCompareBar.Body>
        </ContentExpander>
      </Container>
    </StyledCompareBar>
  )
}

CompareBar.propTypes = {
  heading: PropTypes.string,
  href: PropTypes.string,
}

export { CompareBar }
