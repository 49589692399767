export const isBrowser = () => typeof window !== 'undefined'

const WP_API =
  process.env.NODE_ENV === 'production'
    ? process.env.GATSBY_CMS_URL
    : process.env.GATSBY_CMS_URL

const jwt = isBrowser() ? require('jsonwebtoken') : {}

export const resetPassword = async email => {
  return await await fetch(
    `${WP_API}/wp-json/ecoride/v1/reset/password?email=${email}&url=${process.env.GATSBY_SITE_URL}`,
    { method: 'GET' }
  )
    .then(checkStatus)
    .then(res => res.json())
}

export const changePassword = async payload => {
  return await await fetch(`${WP_API}/wp-json/ecoride/v1/resetpassword`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(res => res.json())
}

export const searchCustomer = async (order, email) => {
  return await await fetch(
    `${WP_API}/wp-json/ecoride/v1/customer?order=${order}&email=${email}`,
    { method: 'GET' }
  )
    .then(checkStatus)
    .then(res => res.json())
}

export const registerCustomer = async payload => {
  return await await fetch(`${WP_API}/wp-json/ecoride/v1/customer/register`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(res => res.json())
}

export const loginCustomer = async credentials => {
  return await await fetch(`${WP_API}/wp-json/jwt-auth/v1/token`, {
    method: 'POST',
    body: JSON.stringify(credentials),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(res => res.json())
    .then(res => {
      const { data } = jwt.decode(res.token)
      res.user_id = data.user.id
      return res
    })
    .then(res => {
      if (res.token) {
        setUser(res)
        return getUser()
      }
      return false
    })
}

export const isTokenExpired = () => {
  const { token } = getUser()
  if (!token) return true

  const decodedToken = jwt.decode(token)
  if (decodedToken.exp * 1000 < new Date().getTime()) return true

  return false
}

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('user')
    ? JSON.parse(window.localStorage.getItem('user'))
    : {}

const setUser = user => {
  window.localStorage.setItem('user', JSON.stringify(user))
}

const setCustomer = customer => {
  window.localStorage.setItem('customer', JSON.stringify(customer))
}

const setOrders = orders => {
  window.localStorage.setItem('orders', JSON.stringify(orders))
}

export const isLoggedIn = () => {
  if (!isBrowser()) {
    return false
  }
  const user = getUser()
  const valid = !!user.token && !isTokenExpired()

  if (!valid) {
    logout(() => {})
  }

  return valid
}

export const logout = callback => {
  setUser({})
  setCustomer([])
  setOrders([])
  callback()
}

const checkStatus = response => {
  if (response.status !== 200 && response.status) {
    throw new Error(response.statusText)
  }
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response
}
