export const gtag = (event, data) => {
  if (!window) {
    return
  }

  if (!window.dataLayer) {
    window.dataLayer = []
  }

  // console.log('GTAG: ', { event, ...data })
  window.dataLayer.push({ event, ...data })
}
