import styled from 'styled-components'
import {
  colors,
  typography,
  spacing,
} from '../../../styles/utilities/variables'
import StyledHeading from '../../../components/Heading/style'
import { Link } from 'gatsby'

const StyledHasContent = styled.div`
  & ${StyledHeading.h6} {
    text-transform: uppercase;
    padding-bottom: ${spacing.base};
    border-bottom: 1px solid ${colors.adaptedLightBlueBackground};
    font-size: ${typography.sizes.small};
    margin-bottom: ${spacing.small} 0;
  }
`

StyledHasContent.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`

StyledHasContent.Link = styled(Link)`
  color: ${colors.primaryDarkBlue};
  font-size: ${typography.sizes.small};
  padding: 12px 0;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:last-child {
    margin-bottom: ${spacing.medium};
  }
`

export default StyledHasContent
