import React, { useRef, useEffect, useState } from 'react'
import { useClickOutside } from '../../hooks/useClickOutside'
import { useHasMounted } from '../../hooks/useHasMounted'
import PropTypes from 'prop-types'
import StyledLanguageSwitcher from './style'

const LanguageSwitcher = props => {
  const { links, open, setOpen } = props

  const hasMounted = useHasMounted()
  const [currentDomain, setCurrentDomain] = useState(null)

  const switcherRef = useRef(null)

  useClickOutside(switcherRef, () => {
    if (open) setOpen(false)
  })

  useEffect(() => {
    if (hasMounted) setCurrentDomain(window.location.hostname)
  }, [hasMounted])

  return (
    <StyledLanguageSwitcher ref={switcherRef} open={open}>
      <StyledLanguageSwitcher.List>
        {links &&
          links.length > 0 &&
          links.map((item, index) => {
            const { title, url } = item.link
            return (
              <StyledLanguageSwitcher.ListItem
                key={index}
                active={currentDomain && url.includes(currentDomain)}
              >
                <a href={url}>{title}</a>
              </StyledLanguageSwitcher.ListItem>
            )
          })}
      </StyledLanguageSwitcher.List>
    </StyledLanguageSwitcher>
  )
}

LanguageSwitcher.propTypes = {
  // myStringProp: PropTypes.string,
  // myBoolProp: PropTypes.bool,
  // And more
}

export { LanguageSwitcher }
