import { useStaticQuery, graphql } from 'gatsby'

export const useHeaderQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    query HeaderQuery($lang: WordPress_LanguageCodeFilterEnum) {
      wordpress {
        headerSettings(language: $lang) {
          data {
            mainMenu {
              label
              type
              link {
                title
                url
              }
              contentColumns {
                columnLinks {
                  link {
                    url
                  }
                }
              }
            }
            languageSwitcher {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const {
    mainMenu,
    languageSwitcher,
  } = staticQuery.wordpress.headerSettings.data

  return { mainMenu: mainMenu, languageSwitcher: languageSwitcher }
}
