import styled, { css } from 'styled-components'
import {
  spacing,
  colors,
  cssEasings,
  mq,
} from '../../styles/utilities/variables'
import StyledIcon from '../Icon/style'
import StyledText from '../Text/style'

const StyledCompareBar = styled.div`
  background-color: ${colors.secondaryLightBlue};
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 13;
  transform: ${props => (props.open ? 'translateY(0%)' : 'translateY(110%)')};

  transition: transform 0.25s ${cssEasings.easeInOutCubic};
  
  ${props =>
    props.open &&
    css`
      transition: transform 0.4s ${cssEasings.easeOutQuart};
    `}

  /* h4 ~ button {
    display: flex;
    text-transform: uppercase;
    padding-left: 2rem !important;
    color: ${colors.secondaryLightBlue};
    font-size: 1.3rem;
    div {
      padding-left: 1rem !important;
    }
    &:hover: {
      border: solid 1px red !important;
      background-color: red;
    }
  } */
`

StyledCompareBar.Body = styled.div`
  display: flex;

  /* @media ${mq.smallHeight} {
    max-height: 0;
  } */
`

StyledCompareBar.Top = styled.div`
  border-bottom: 1px solid #d3e3ea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.small} 0;
`

StyledCompareBar.TopControls = styled.div`
  display: flex;
  align-items: center;
`

StyledCompareBar.CloseButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  margin-left: ${spacing.large};

  ${StyledText} {
    margin-bottom: 0;
  }

  ${StyledIcon} {
    margin-left: ${spacing.xxSmall};
  }
`

StyledCompareBar.Heading = styled.h4`
  font-size: 1.6rem;
  text-transform: uppercase;
  color: ${colors.primaryDarkBlue};
  letter-spacing: 0.04em;
  align-self: center;
  cursor: pointer;
`

const StyledCompareCard = styled.div`
  height: 20rem;
  border-radius: 1.9rem;
  padding: 1.6rem;
  width: 25%;
  display: flex;
  flex-direction: column;
  background-color: ${props =>
    props.id ? 'white' : 'rgba(245, 249, 250, 0.6)'};
  margin: ${spacing.medium};

  & > div {
    display: flex;
    flex-direction: row-reverse;
    i {
      cursor: pointer;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`
StyledCompareCard.Image = styled.figure`
  background-size: cover;
  background-position: center;
  height: 14rem;
`

export default StyledCompareBar
