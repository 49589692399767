import { useMemo } from 'react'

/**
 * Strip tags
 * @param {*} string
 */
export const stripTags = (string = '') => {
  return string.replace(/<[^>]*>?/gm, '')
}
/**
 * Search in array
 * @param {*} options
 * @param {*} key
 * @param {*} value
 */
export const useSearch = (options, key, value) => {
  return useMemo(() => {
    const re = new RegExp(value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
    return options.filter(t => re.test(t[key]))
  }, [options, key, value])
}

export const formatErrors = response => {
  let err = Object.values(response.errors)
  return err.join(' ')
}
