import styled from 'styled-components'

const StyledIcon = styled.div`
  font-size: 0;
  line-height: 1;

  i {
    font-size: ${({ size }) => (size ? size + 'px' : '24px')};

    max-width: ${({ size }) => (size ? size + 'px' : '24px')};
    visibility: hidden;
    overflow: hidden;
    display: none;
    color: ${({ color }) => (color ? color + ' !important' : 'inherit')};
    .wf-active & {
      max-width: none;
      visibility: visible;
      overflow: visible;
      display: block;
    }
  }
`

export default StyledIcon
