import React from 'react'
import StyledCheckoutHeader from './style'

import { Row } from '../../components/Row'
import { Container } from '../../components/Container'
import { Logo } from '../../components/Svg'

const CheckoutHeader = () => {
  return (
    <StyledCheckoutHeader id='header'>
      <Container>
        <Row justify={'center'}>
          <StyledCheckoutHeader.Logo to={'/'}>
            <Logo />
          </StyledCheckoutHeader.Logo>
        </Row>
      </Container>
    </StyledCheckoutHeader>
  )
}

export default CheckoutHeader
