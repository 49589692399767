import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { colors, cssEasings, mq } from '../../styles/utilities/variables'

const StyledFlyOutPanel = styled.div`
  width: 51rem;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  position: fixed;
  z-index: 1006;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props =>
    props.backgroundColor
      ? colors[props.backgroundColor]
      : colors.adaptedLightBlue};

  transform: ${props => (props.open ? 'translateX(0%)' : 'translateX(105%)')};
  overflow: scroll;

  transition: transform 0.25s ${cssEasings.easeInOutCubic};

  ${props =>
    props.open &&
    css`
      transition: transform 0.4s ${cssEasings.easeOutQuart};
    `}

  iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  @media ${mq.mobile} {
    ${props =>
      !props.alwaysFromSide &&
      css`
        width: 100%;
        height: 90%;
        max-width: none;
        transform: ${props =>
          props.open ? 'translate(0%)' : 'translateY(110%)'};
      `}
  }
`

StyledFlyOutPanel.Overlay = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1005;
`

export default StyledFlyOutPanel
