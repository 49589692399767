import React from 'react'
import PropTypes from 'prop-types'
import StyledGrid from './style'

const Grid = props => {
  const { children } = props

  return <StyledGrid {...props}>{children}</StyledGrid>
}

Grid.propTypes = {
  children: PropTypes.any,
  col: PropTypes.number,
  mobileCol: PropTypes.number,
  tabletCol: PropTypes.number,
  colGap: PropTypes.string,
  rowGap: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
  responsive: PropTypes.bool,
  autoflow: PropTypes.bool,
}

export { Grid }
