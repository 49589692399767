import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { AppContext, closeCart } from '../../config/context'

import { FlyOutPanel } from '../../components/FlyOutPanel'
import { Cart } from '../../components/Cart'
import { CompareBar } from '../../components/CompareBar'
import { CookieBar } from '../../components/CookieBar'
import { useTranslation } from '../../hooks/useTranslation'
import { getUrl } from '../../helpers/url'
import { HideIntercom } from '../../styles/base/hideIntercom'

const Panels = props => {
  const [state, dispatch] = useContext(AppContext)
  const { cartIsOpen } = state

  const getTranslation = useTranslation()

  const staticQuery = useStaticQuery(graphql`
    query PanelsQuery($lang: WordPress_LanguageCodeFilterEnum) {
      wordpress {
        specificPagesSettings(language: $lang) {
          data {
            comparisonPage {
              ... on WordPress_Page {
                uri
              }
            }
          }
        }
        footerSettings(language: $lang) {
          data {
            footerCookieBarConsent
            footerCookieBarMessage
            footerCookieBarPolicyLink {
              title
              url
            }
          }
        }
      }
    }
  `)

  const comparisonPage =
    staticQuery.wordpress.specificPagesSettings.data.comparisonPage

  const {
    footerCookieBarMessage,
    footerCookieBarConsent,
    footerCookieBarPolicyLink,
  } = staticQuery.wordpress.footerSettings.data

  return (
    <>
      {cartIsOpen && <HideIntercom />}
      <FlyOutPanel
        handleClose={() => dispatch(closeCart())}
        open={cartIsOpen}
        alwaysFromSide
      >
        <Cart inCheckout={false} />
      </FlyOutPanel>
      <CompareBar
        heading={getTranslation('panelShowProductsText')}
        href={
          comparisonPage && comparisonPage.uri
            ? getUrl(comparisonPage.uri)
            : null
        }
      />
      <CookieBar
        message={footerCookieBarMessage}
        consent={footerCookieBarConsent}
        readMore={footerCookieBarPolicyLink}
      />
    </>
  )
}

export default Panels
