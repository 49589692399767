import styled from 'styled-components'
import { Link } from 'gatsby'
import { spacing, mq, colors } from '../../styles/utilities/variables'

export const headerHeights = {
  desktop: '72px',
  mobile: '72px',
}

const StyledCheckoutHeader = styled.div`
  background: ${colors.primaryDarkBlue};
  z-index: 1003;
  height: ${headerHeights.desktop};
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;

  @media ${mq.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${headerHeights.mobile};
  }
`

StyledCheckoutHeader.Logo = styled(Link)`
  font-size: 0;
  margin-right: ${spacing.small};
  z-index: 5;
`

export default StyledCheckoutHeader
