/**
 * Get slug
 * @param {*} url
 */
export const getSlug = url => {
  return url.substring(url.indexOf('/', 8))
}

/**
 * Get filename
 * @param {*} url
 */
export const getFileName = url => {
  if (!url) url = ''
  return url.split(/(\\|\/)/g).pop()
}

export const getBaseUrl = () => {
  return process.env.GATSBY_SITE_URL ? process.env.GATSBY_SITE_URL : '/'
}

const parseUploadsUrl = url => {
  if (process.env.GATSBY_CMS_URL && process.env.GATSBY_SITE_URL) {
    if (url.startsWith('/')) return process.env.GATSBY_CMS_URL + url
    return url.replace(process.env.GATSBY_SITE_URL, process.env.GATSBY_CMS_URL)
  }
  return url
}

/**
 * Add trailing slash if none
 * @param {*} path
 */
const checkTrailingSlash = path => {
  return path.length && path.substring(path.length - 1) !== '/'
    ? path + '/'
    : path
}

/**
 * Parse url
 * @param {*} url
 * @param {*} onlyRelative
 */
export const getUrl = url => {
  try {
    if (url.startsWith('#')) return url // Anchor link
    if (url.indexOf('/uploads/') >= 0) return parseUploadsUrl(url) // Asset
    if (
      process.env.GATSBY_SITE_URL &&
      !url.startsWith(process.env.GATSBY_SITE_URL) &&
      process.env.GATSBY_CMS_URL &&
      !url.startsWith(process.env.GATSBY_CMS_URL) &&
      process.env.GATSBY_CMS_URL_EN &&
      !url.startsWith(process.env.GATSBY_CMS_URL_EN)
    ) {
      const prod = [
        'https://www.ecoride.se',
        'https://ecoride.se',
        'http://www.ecoride.se',
        'http://ecoride.se',
      ]
      if (
        process.env.NODE_ENV === 'development' ||
        !prod.includes(window.location.origin)
      ) {
        return url.replace('https://admin.ecoride.se', '')
      }
      return url // External??
    }

    if (url.startsWith('/')) return checkTrailingSlash(url) // Already internal we assume

    const path = getPath(url)
    if (['/front-page/', '/home/', '/frontpage/', '/startsida/'].includes(path))
      return '/'
    return checkTrailingSlash(path)
  } catch (error) {
    return url
  }
}

const getPath = url => {
  var match = url.match(
    /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
  )
  return match && match[5]
}

/**
 * Get URL
 * @param {*} url
 */
// export const getUrl = url => {
//   try {
//     var parser = new URL(url);
//     const pathname = parser.pathname
//     if (['/front-page', '/home'].includes(pathname)) return '/'
//     return pathname
//   } catch (error) {
//     return url
//   }
// }

const isChildOf = (child, parent) => {
  if (child === parent) return false
  const parentTokens = parent.split('/').filter(i => i.length)
  const childTokens = child.split('/').filter(i => i.length)

  return parentTokens.every((t, i) => childTokens[i] === t)
}

/**
 * Get URL
 * @param {*} item
 */
export const shouldItemBeActive = item => {
  const currentUrl =
    typeof window !== 'undefined' ? getUrl(window.location.href) : ''

  let allUrls = []
  if (item.link && item.link.url) allUrls.push(getUrl(item.link.url))

  const contentColumnUrls = item.contentColumns
    ? item.contentColumns.reduce((columnAcc, column) => {
        let columnLinkUrls = column.columnLinks
          ? column.columnLinks.reduce((linkAcc, link) => {
              return link.link && link.link.url
                ? [...linkAcc, getUrl(link.link.url)]
                : linkAcc
            }, [])
          : []
        return [...columnAcc, ...columnLinkUrls]
      }, [])
    : []

  allUrls = [...allUrls, ...contentColumnUrls]

  return allUrls.includes(currentUrl)
    ? true
    : allUrls.reduce((acc, url) => {
        return isChildOf(currentUrl, url) ? true : acc
      }, false)
}
