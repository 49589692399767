import styled from 'styled-components'
import { spacing, colors } from '../../styles/utilities/variables'

const StyledMegaMenu = styled.div`
  padding: ${spacing.large} 0;
  background: ${colors.secondaryLightBlue};
  color: ${colors.primaryDarkBlue};
  z-index: 1004;
  position: fixed;
  width: 100%;
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  opacity: ${props => (props.active ? '1' : '0')};
  transition: opacity 0.3s;
`

export default StyledMegaMenu
