import React from 'react'
import PropTypes from 'prop-types'
import { isInternal } from '../../helpers/link'
import StyledButton from './style'
import { Icon } from '../Icon'

const Button = props => {
  const {
    children,
    href,
    handleClick,
    color,
    disabled,
    outline,
    transparent,
    icon,
    iconBefore = false,
    fullWidth = false,
    textColor,
    target,
    blank,
    small,
    large,
    active = false,
    svg,
    maxWidth,
  } = props

  const dirTarget =
    (blank ? '_blank' : null) || (target ? target : null) || null

  const size = (small && 'small') || (large && 'large') || 'medium'

  const Component = href
    ? isInternal(href)
      ? StyledButton.Internal
      : StyledButton.External
    : StyledButton

  return (
    <Component
      to={href && isInternal(href) ? href : null}
      href={href && !isInternal(href) ? href : null}
      color={color}
      onClick={handleClick}
      disabled={disabled ? 1 : 0}
      outline={outline}
      $transparent={transparent ? 1 : 0}
      icon={icon}
      iconbefore={iconBefore ? 1 : 0}
      fullwidth={fullWidth ? 1 : 0}
      textcolor={textColor}
      target={dirTarget}
      size={size}
      $active={active ? 1 : 0}
      svg={svg}
      maxwidth={maxWidth}
    >
      {svg}
      {icon && iconBefore && <Icon icon={icon} />}
      <span>{children}</span>
      {icon && !iconBefore && <Icon icon={icon} />}
    </Component>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  transparent: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.string,
  iconBefore: PropTypes.bool,
  href: PropTypes.string,
  handleClick: PropTypes.func,
  blank: PropTypes.bool,
  target: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

export { Button }
