import React from 'react'
import StyledCompareCard from './style'
import { Icon } from '../Icon'

const CompareCard = props => {
  const { image, id, name, handleRemoveClick } = props

  return (
    <>
      <StyledCompareCard id={id}>
        {id && image && name && (
          <>
            <Icon size={26} icon={'clear'} handleClick={handleRemoveClick} />
            <StyledCompareCard.Image
              style={{ backgroundImage: `url(${image})` }}
              title={name}
            />
          </>
        )}
      </StyledCompareCard>
    </>
  )
}

export { CompareCard }
