import React from 'react'
import PropTypes from 'prop-types'
import { getIsClient } from '../../../helpers/utilities'
import { getUrl } from '../../../helpers/url'
import StyledHasContent from './style'
import { isInternal } from '../../../helpers/link'
import { Heading } from '../../../components/Heading'
import { Col } from '../../../components/Col'
import { Grid } from '../../../components/Grid'
import { SPACING } from '../../../helpers/constants'

const HasContent = ({ content, showContent, isCurrentPage }) => {
  if (!getIsClient()) return null
  const currentPage = isCurrentPage ? window.location.pathname : null

  if (!content) return null
  return (
    <StyledHasContent showContent={showContent}>
      <Grid col={5} colGap={SPACING.MEDIUM}>
        {content.map((item, key) => {
          const { columnWide, columnLinks, columnTitle, smallFont } = item

          return (
            <Col key={key} column={columnWide ? 'span 2' : 'span 1'}>
              <Heading type={'h3'} size={5}>
                {columnTitle}
              </Heading>
              <StyledHasContent.List wide={columnWide} small={smallFont}>
                {columnLinks.map((item, key) => {
                  if (item.link === null) return null
                  const { url, title } = item.link
                  const parsedUrl = getUrl(url)

                  if (isCurrentPage && currentPage === parsedUrl) return null
                  return (
                    <StyledHasContent.ListItem key={key}>
                      {isInternal(parsedUrl) ? (
                        <StyledHasContent.InternalLink to={parsedUrl}>
                          {title}
                        </StyledHasContent.InternalLink>
                      ) : (
                        <StyledHasContent.ExternalLink href={parsedUrl}>
                          {title}
                        </StyledHasContent.ExternalLink>
                      )}
                    </StyledHasContent.ListItem>
                  )
                })}
              </StyledHasContent.List>
            </Col>
          )
        })}
      </Grid>
    </StyledHasContent>
  )
}

HasContent.propTypes = {
  content: PropTypes.array,
  showContent: PropTypes.bool,
  isCurrentPage: PropTypes.bool,
}

export { HasContent }
