import React from 'react'
import StyledCol from './style'
import PropTypes from 'prop-types'

const Col = props => {
  const { children } = props

  return (
    <StyledCol {...props} className={'col'}>
      {children}
    </StyledCol>
  )
}

Col.propTypes = {
  column: PropTypes.string,
  row: PropTypes.string,
  justify: PropTypes.string,
  align: PropTypes.string,
}

export { Col }
