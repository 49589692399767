import React from 'react'
import PropTypes from 'prop-types'
import StyledHamburger from './style'

const Hamburger = ({ active, text, handleClick }) => {
  return (
    <StyledHamburger active={active} onClick={handleClick}>
      <StyledHamburger.Text>{text}</StyledHamburger.Text>
      <StyledHamburger.Container>
        <StyledHamburger.Line />
        <StyledHamburger.Line />
        <StyledHamburger.Line />
      </StyledHamburger.Container>
    </StyledHamburger>
  )
}

Hamburger.propTypes = {
  active: PropTypes.bool,
  handleClick: PropTypes.func,
  text: PropTypes.string,
}

export default Hamburger
