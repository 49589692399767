import { useStaticQuery, graphql } from 'gatsby'

export const useMegaMenuQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    query MegaMenuQuery($lang: WordPress_LanguageCodeFilterEnum) {
      wordpress {
        headerSettings(language: $lang) {
          data {
            mainMenu {
              contentColumns {
                columnTitle
                columnWide
                smallFont
                columnLinks {
                  link {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const cleanQuery = staticQuery.wordpress.headerSettings.data.mainMenu
  return cleanQuery
}
