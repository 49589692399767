import styled from 'styled-components'
import { colors, typography, mq } from '../../styles/utilities/variables'

const StyledText = styled.p`
  color: ${colors.black};
  font-size: ${props =>
    props.size ? typography.sizes[props.size] : typography.sizes.base};
  column-count: ${props => (props.columns ? props.columns : 'auto')};

  a:not([class]) {
    text-decoration: underline;
  }

  @media ${mq.mobile} {
    column-count: auto;
  }

  /* &:last-child {
    margin-bottom:0;
  } */
`

export default StyledText
