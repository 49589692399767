import React from 'react'
import { isInternal } from '../../helpers/link'
import { getUrl } from '../../helpers/url'
import StyledFooter from './style'
import { useFooterQuery } from './hook'
import { SPACING, COLORS } from '../../helpers/constants'
import { Container } from '../../components/Container'
import { Row } from '../../components/Row'
import { Heading } from '../../components/Heading'
import { Divider } from '../../components/Divider'
import { Logo } from '../../components/Svg'
import klarnaLogo from '../../assets/images/klarna-logo.png'

const Footer = props => {
  const footerSetting = useFooterQuery()
  const { footerColumns } = footerSetting

  const firstColumn =
    footerColumns && footerColumns.length
      ? footerColumns.map((item, key) => key <= 1 && item).filter(Boolean)
      : null

  const secondColumn =
    footerColumns && footerColumns.length
      ? footerColumns.map((item, key) => key >= 2 && item).filter(Boolean)
      : null
  return (
    <StyledFooter id='footer'>
      <Container>
        <Row align={'flex-start'}>
          <StyledFooter.Column>
            {firstColumn &&
              firstColumn.map((item, key) => {
                return (
                  <StyledFooter.Wrapper key={key}>
                    <Heading type={'h3'} size={5}>
                      {item.columnTitle}
                    </Heading>
                    <StyledFooter.List>
                      {item.columnLinks &&
                        item.columnLinks.map((item, key) => {
                          const parsedUrl = getUrl(item.link.url)
                          return (
                            <StyledFooter.ListItem key={key}>
                              {isInternal(parsedUrl) ? (
                                <StyledFooter.InternalLink to={parsedUrl}>
                                  {item.link.title}
                                </StyledFooter.InternalLink>
                              ) : (
                                <StyledFooter.ExternalLink href={parsedUrl}>
                                  {item.link.title}
                                </StyledFooter.ExternalLink>
                              )}
                            </StyledFooter.ListItem>
                          )
                        })}
                    </StyledFooter.List>
                  </StyledFooter.Wrapper>
                )
              })}
          </StyledFooter.Column>
          <StyledFooter.Column>
            {secondColumn &&
              secondColumn.map((item, key) => {
                return (
                  <StyledFooter.Wrapper key={key}>
                    <Heading type={'h3'} size={5}>
                      {item.columnTitle}
                    </Heading>
                    <StyledFooter.List>
                      {item.columnLinks &&
                        item.columnLinks.map((item, key) => {
                          if (item.link === null) return null
                          const parsedUrl = getUrl(item.link.url)
                          return (
                            <StyledFooter.ListItem key={key}>
                              {isInternal(parsedUrl) ? (
                                <StyledFooter.InternalLink to={parsedUrl}>
                                  {item.link.title}
                                </StyledFooter.InternalLink>
                              ) : (
                                <StyledFooter.ExternalLink href={parsedUrl}>
                                  {item.link.title}
                                </StyledFooter.ExternalLink>
                              )}
                            </StyledFooter.ListItem>
                          )
                        })}
                    </StyledFooter.List>
                  </StyledFooter.Wrapper>
                )
              })}
          </StyledFooter.Column>
        </Row>
        <Divider
          size={SPACING.LARGE}
          line={COLORS.ADAPTED_LIGHT_BLUE_BACKGROUND}
        />
        <Row>
          <StyledFooter.Images>
            <img
              width={'100%'}
              height={'100%'}
              src={klarnaLogo}
              alt={'Klarna'}
            />
          </StyledFooter.Images>
          <StyledFooter.Logo to={'/'}>
            <Logo />
          </StyledFooter.Logo>
        </Row>
      </Container>
    </StyledFooter>
  )
}

export default Footer
