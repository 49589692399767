import {
  boxShadow,
  colors,
  mq,
  spacing,
  typography,
} from '../../styles/utilities/variables'
import styled, { css } from 'styled-components'

const StyledCookieBar = styled.aside`
  background: ${colors.secondaryLightBlue};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(110%);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
  z-index: 1100;
  box-shadow: ${boxShadow};

  p {
    color: ${colors.black};
  }

  ${props =>
    props.visible &&
    css`
      transform: translateY(0%);
      opacity: 1;
    `}
`

StyledCookieBar.Inner = styled.div`
  width: 100%;
  font-size: ${typography.sizes.small};
  padding: ${spacing.medium} 0;
  text-align: center;
`

StyledCookieBar.Buttons = styled.div`
  display: inline-flex;
  margin: 0 auto;
  justify-content: center;
  margin-top: ${spacing.small};

  & > * {
    margin: 0 10px;
  }

  @media ${mq.mobile} {
    flex-direction: column;
    margin-top: 0;

    & > * {
      margin: 10px 0 0 0;
    }
  }
`

export default StyledCookieBar
