import styled from 'styled-components'
import {
  spacing,
  colors,
  typography,
  mq,
  displayNone,
} from '../../styles/utilities/variables'
import StyledHeader from '../../components/Heading/style'
import StyledRow from '../../components/Row/style'
import { Link } from 'gatsby'
import StyledContainer from '../../components/Container/style'

const hovereffects = `
  &:hover {
    opacity: 0.6;
  }

  &:[aria-current] {
    color: #addbe3;
  }
`

const StyledFooter = styled.footer`
  padding: ${spacing.xxLarge} 0;
  background: ${colors.primaryDarkBlue};
  color: ${colors.white};

  ${StyledHeader.h3} {
    color: ${colors.adaptedLightBlueBackground};
    margin-bottom: ${spacing.xSmall};
    text-transform: uppercase;
  }

  @media ${mq.tablet} {
    & ${StyledRow} {
      padding-bottom: ${spacing.large};
    }
  }

  @media ${mq.mobile} {
    padding: ${spacing.medium} 0;
  }

  & ${StyledContainer} {
    @media ${mq.mobile} {
      display: none;
    }
  }
`

StyledFooter.Wrapper = styled.div`
  width: 100%;
  max-width: 100%;

  &:not(:last-child) {
    margin-right: ${spacing.large};
  }
`

StyledFooter.Column = styled.div`
  display: flex;
  width: 50%;
  ${displayNone.mobile}

  &:first-child {
    margin-right: ${spacing.large};

    @media ${mq.tablet} {
      width: 70%;
    }
  }

  &:last-child {
    @media ${mq.tablet} {
      width: 30%;
      flex-direction: column;

      & ${StyledFooter.Wrapper} {
        &:not(:last-child) {
          padding-bottom: ${spacing.xLarge};
        }
      }
    }
  }
`

StyledFooter.List = styled.ul`
  list-style: none;
  border-top: 1px solid ${colors.adaptedLightBlueBackground};
  padding-top: ${spacing.small};
`

StyledFooter.ListItem = styled.li`
  line-height: 1.2;
  &:not(:last-child) {
    margin-bottom: ${spacing.small};
  }
`

StyledFooter.InternalLink = styled(Link)`
  color: ${colors.white};
  font-size: ${typography.sizes.medium};
  ${hovereffects}
`

StyledFooter.ExternalLink = styled.a`
  color: ${colors.white};
  font-size: ${typography.sizes.medium};
  ${hovereffects}
`

StyledFooter.Images = styled.div`
  img {
    width: 15rem;
  }
`

StyledFooter.Logo = styled(Link)`
  margin: 0 0 0 auto;
`

export default StyledFooter
