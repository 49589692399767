import React from 'react'
import PropTypes from 'prop-types'
import StyledDivider from './style'

const Divider = props => {
  const { size, line } = props

  let finalSize = {}

  if (typeof size === 'object' && size !== null) {
    finalSize.desktop = size.desktop
    finalSize.tablet = size.tablet ? size.tablet : size.desktop
    finalSize.mobile = size.mobile ? size.mobile : size.desktop
  } else {
    finalSize.desktop = size
    finalSize.tablet = size
    finalSize.mobile = size
  }

  const debug = false

  return (
    <StyledDivider
      debug={debug}
      size={finalSize.desktop}
      sizeTablet={finalSize.tablet}
      sizeMobile={finalSize.mobile}
      line={line}
    />
  )
}

Divider.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  line: PropTypes.string,
}

export { Divider }
