import styled from 'styled-components'
import { spacing } from '../../styles/utilities/variables'
import StyledIcon from '../Icon/style'

const StyledCompareCard = styled.div`
  position: relative;
  height: 12rem;
  border-radius: 1.9rem;
  padding: 1.6rem;
  width: 25%;
  background-color: ${props =>
    props.id ? 'white' : 'rgba(245, 249, 250, 0.6)'};
  margin: ${spacing.medium};

  ${StyledIcon} {
    position: absolute;
    top: ${spacing.xSmall};
    right: ${spacing.xSmall};
    cursor: pointer;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`
StyledCompareCard.Image = styled.figure`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`

export default StyledCompareCard
