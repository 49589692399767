import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import {
  colors,
  spacing,
  mq,
  typography,
} from '../../styles/utilities/variables'
import StyledDivider from '../Divider/style'
import StyledIcon from '../Icon/style'
import StyledForm from '../Form/style'

const cartPadding = () => css`
  /* padding: 0 ${spacing.large}; */
  padding: ${props => (props.inCheckout ? '0' : `0 ${spacing.large}`)};

  @media ${mq.tablet} {
    /* padding: 0 ${spacing.medium}; */
    padding: ${props => (props.inCheckout ? '0' : `0 ${spacing.medium}`)};
  }
`

const StyledCart = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${StyledDivider} {
    opacity: 0.3;
  }
`

StyledCart.CloseCartButton = styled.button`
  position: absolute;
  top: ${spacing.base};
  right: ${spacing.base};
  background: transparent;
  border: none;
  outline: none;

  &:focus,
  &:active {
    outline: none;
  }
`

StyledCart.Header = styled.header`
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${cartPadding};
`

StyledCart.HeaderInner = styled.div`
  padding-bottom: ${spacing.base};
  border-bottom: 1px solid ${rgba(colors.primaryDarkBlue, 0.3)};
`

StyledCart.Body = styled.div`
  position: relative;
  flex: 1;
  overflow-y: ${props => (props.inCheckout ? 'visible' : 'auto')};
  ${cartPadding};
  padding-top: ${spacing.base};
  padding-bottom: ${spacing.base};
  min-height: ${props => (props.inCheckout ? '10rem' : '0')};

  @media ${mq.tablet} {
    padding-top: ${spacing.base};
    padding-bottom: ${spacing.base};
  }
`

StyledCart.EmptyMessage = styled.p`
  position: absolute;
  text-align: center;
  font-style: italic;
  color: rgba(0, 49, 69, 0.7);
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: ${props => (props.inCheckout ? '2rem' : '0')};
`

StyledCart.TextRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.xxSmall};
  font-family: ${typography.secondaryFont};
  /* font-size: ${typography.sizes.small}; */
`

StyledCart.Footer = styled.div`
  border-top: 1px solid ${rgba(colors.primaryDarkBlue, 0.3)};
`

StyledCart.FooterInner = styled.div`
  ${cartPadding};
  padding-top: ${spacing.medium};
  padding-bottom: ${spacing.medium};

  @media ${mq.tablet} {
    padding-top: ${spacing.base};
    padding-bottom: ${spacing.base};
  }

  & ${StyledCart.TextRow} {
    font-weight: 600;
  }
`

StyledCart.CartProduct = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  ${StyledForm.Select} {
    background-color: transparent;
    border-color: ${colors.primaryDarkBlue};
    padding: 0.6rem 2rem 0.6rem 1.2rem;
    background-size: 2.4rem;
    max-width: 70px;
    background-position: right 0.4rem center;
    font-family: ${typography.secondaryFont};
  }
`

StyledCart.CartProductImageWrapper = styled.div`
  width: 16rem;
  margin-right: ${spacing.small};
`

StyledCart.CartProductImage = styled.img`
  width: 16rem;
  margin-right: ${spacing.small};
`

StyledCart.CartProductText = styled.div`
  font-family: ${typography.secondaryFont};
  padding-right: 4rem;
  line-height: 1.4;

  p {
    font-size: ${typography.sizes.small};
    margin-bottom: ${spacing.xxxSmall};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

StyledCart.RemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  background: ${colors.white};
  border-radius: 50%;
  border: 1px solid rgba(0, 49, 69, 0.7);
  outline: none;

  &:focus,
  &:active {
    outline: none;
  }

  ${StyledIcon} {
    color: ${colors.primaryDarkBlue};
  }
`

export default StyledCart
