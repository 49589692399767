import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import StyledFlyOutPanel from './style'

const FlyOutPanel = props => {
  const {
    open = false,
    children,
    handleClose,
    alwaysFromSide = false,
    backgroundColor = null,
  } = props
  return (
    <>
      <AnimatePresence>
        {open && (
          <StyledFlyOutPanel.Overlay
            onClick={handleClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
      <StyledFlyOutPanel
        open={open}
        alwaysFromSide={alwaysFromSide}
        backgroundColor={backgroundColor}
      >
        {children}
      </StyledFlyOutPanel>
    </>
  )
}

FlyOutPanel.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any,
  handleClose: PropTypes.func,
  alwaysFromSide: PropTypes.bool,
  backgroundColor: PropTypes.string,
}

export { FlyOutPanel }
