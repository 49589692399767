import styled, { css } from 'styled-components'
import { spacing, colors, mq } from '../../styles/utilities/variables'

const lined = props => css`
  border-bottom: 1px solid ${colors[props.line]};
`

const StyledDivider = styled.div`
  height: 0;
  width: 100%;
  margin: 0;
  display: block;
  position: relative;

  background: ${props => (props.debug ? 'red' : 'transparent')};

  padding-top: ${props => (props.size ? spacing[props.size] : spacing.small)};
  padding-bottom: ${props =>
    props.size ? spacing[props.size] : spacing.small};

  @media ${mq.tablet} {
    padding-top: ${props =>
      props.sizeTablet ? spacing[props.sizeTablet] : spacing.small};
    padding-bottom: ${props =>
      props.sizeTablet ? spacing[props.sizeTablet] : spacing.small};
  }

  @media ${mq.mobile} {
    padding-top: ${props =>
      props.sizeMobile ? spacing[props.sizeMobile] : spacing.small};
    padding-bottom: ${props =>
      props.sizeMobile ? spacing[props.sizeMobile] : spacing.small};
  }

  &::after {
    content: '';
    background-color: ${colors.lightGrey};
    position: absolute;
    display: inline-block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    ${props => (props.line ? lined : '')};
  }
`

export default StyledDivider
