import React from 'react'
import PropTypes from 'prop-types'
import StyledMobileMenu from './style'
import { getUrl } from '../../helpers/url'
import { useMegaMenuQuery } from '../MegaMenu/hook'
import { useFooterQuery } from '../Footer/hook'
import { NavItem } from './NavItem'
import { OverflowHiddenStyle } from '../../styles/base/overflowHiddenStyle'
import { Icon } from '../../components/Icon'
import { HideIntercom } from '../../styles/base/hideIntercom'
import { isInternal } from '../../helpers/link'

const MobileMenu = ({ open, menuItems, languages = [] }) => {
  const megamenuQuery = useMegaMenuQuery()

  const footerSetting = useFooterQuery()
  const { footerColumns } = footerSetting

  const secondColumn =
    footerColumns && footerColumns.length
      ? footerColumns.map((item, key) => key >= 2 && item).filter(Boolean)
      : null

  return (
    <StyledMobileMenu open={open}>
      {open && <OverflowHiddenStyle />}
      {open && <HideIntercom />}
      <StyledMobileMenu.Content>
        <StyledMobileMenu.InnerContent>
          {menuItems.map((item, key) => {
            const parsedUrl =
              item.type === 'link' && item.link ? getUrl(item.link.url) : null
            return (
              <NavItem
                key={key}
                to={parsedUrl}
                hascontent={item.type === 'content'}
                content={megamenuQuery[key].contentColumns}
                internClick={item.type === 'content'}
                label={item.label}
              />
            )
          })}
          {secondColumn && (
            <StyledMobileMenu.SecondColumn>
              {secondColumn.map(
                item =>
                  item.columnLinks &&
                  item.columnLinks.map((i, index) => {
                    if (i.link === null) return null

                    const { url, title } = i.link
                    return (
                      <MobileMenuLink key={index} to={getUrl(url)}>
                        {title}
                      </MobileMenuLink>
                    )
                  })
              )}
            </StyledMobileMenu.SecondColumn>
          )}
        </StyledMobileMenu.InnerContent>
      </StyledMobileMenu.Content>

      {languages && (
        <StyledMobileMenu.Footer>
          <Icon icon={'language'} size={20} />
          {languages.map((language, key) => {
            const { link } = language
            return (
              <React.Fragment key={key}>
                <MobileMenuLink to={link.url}>{link.title}</MobileMenuLink>
                {key < languages.length - 1 && <span>&#183;</span>}
              </React.Fragment>
            )
          })}
        </StyledMobileMenu.Footer>
      )}
    </StyledMobileMenu>
  )
}

const MobileMenuLink = props => {
  const { children, to } = props
  const checkIsInternal = isInternal(to)
  return (
    <>
      {checkIsInternal ? (
        <StyledMobileMenu.SmallNavItemInternal {...props}>
          {children}
        </StyledMobileMenu.SmallNavItemInternal>
      ) : (
        <StyledMobileMenu.SmallNavItemExternal
          target={'_blank'}
          href={to}
          {...props}
        >
          {children}
        </StyledMobileMenu.SmallNavItemExternal>
      )}
    </>
  )
}

MobileMenu.propTypes = {
  open: PropTypes.bool,
  menuItems: PropTypes.array,
}

export default MobileMenu
