import React from 'react'
import PropTypes from 'prop-types'
import StyledRow from './style'

const Row = props => {
  const { children, spacing, justify, align, auto, height, responsive } = props
  const styledRowProps = { spacing, justify, align, auto, height, responsive }

  return (
    <>
      <StyledRow {...styledRowProps}>{children}</StyledRow>
    </>
  )
}

Row.propTypes = {
  spacing: PropTypes.string,
  justify: PropTypes.string,
  align: PropTypes.string,
  auto: PropTypes.bool,
  height: PropTypes.string,
  responsive: PropTypes.bool,
  children: PropTypes.any,
}

export { Row }
