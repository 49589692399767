/**
 * Is link internal?
 */
export const isInternal = url => {
  if (url.charAt(0) === '/') {
    return true
  } else {
    let baseUrl = process.env.GATSBY_SITE_URL
      ? process.env.GATSBY_SITE_URL
      : 'http://localhost:8000'
    return url.includes(baseUrl)
  }
}
