import React from 'react'
import PropTypes from 'prop-types'
import { getUrl } from '../../../helpers/url'
import StyledHasContent from './style'
import { Heading } from '../../../components/Heading'
import { ContentExpander } from '../../../components/ContentExpander'

const HasContent = ({ content, open }) => {
  if (content === null) return null
  return (
    <ContentExpander open={open}>
      <StyledHasContent>
        {content.map((item, index) => {
          const { columnTitle, columnLinks } = item
          return (
            <React.Fragment key={index}>
              <Heading type={'h6'} size={6}>
                {columnTitle}
              </Heading>
              <StyledHasContent.Wrapper>
                {columnLinks.map((item, key) => {
                  if (item.link === null) return null
                  return (
                    <StyledHasContent.Link key={key} to={getUrl(item.link.url)}>
                      {item.link.title}
                    </StyledHasContent.Link>
                  )
                })}
              </StyledHasContent.Wrapper>
            </React.Fragment>
          )
        })}
      </StyledHasContent>
    </ContentExpander>
  )
}

HasContent.propTypes = {
  content: PropTypes.array,
  open: PropTypes.bool,
}

export { HasContent }
