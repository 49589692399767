import styled, { css } from 'styled-components'
import {
  colors,
  spacing,
  typography,
  radius,
  maxWidths,
  mq,
} from '../../styles/utilities/variables'
import caretDown from '../../assets/images/caret-down.svg'

const StyledForm = styled.form`
  max-width: ${maxWidths.base};
  width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
`

const StyledButton = styled.button`
  @media ${mq.mobile} {
    max-width: 100%;
    font-size: 16px;
  }
`
const baseInputStyle = `
  width: 100%;
  background: ${colors.white};
  padding: 1.2rem 1.2rem;
  font-family: ${typography.primaryFont};
  font-size: ${typography.sizes.small};
  border-radius: ${radius.small};
  border: 1px solid ${colors.borderColor};
  position: relative;
  z-index: 2;
`

StyledForm.Input = styled.input`
  ${baseInputStyle};
  max-width: ${maxWidths.input};
  
  @media ${mq.mobile} {
    max-width: 100%;
    font-size: 16px;
  }

  &:focus {
    outline: ${colors.primaryDarkBlue};
  }

  
  ${props =>
    props.valid &&
    css`
      && {
        border-color: ${colors.success};
      }
    `}

  ${props =>
    props.inValid &&
    css`
      && {
        border-color: ${colors.primaryRed};
      }
    `}
  

  ${props =>
    props.transparent &&
    css`
      & {
        background: transparent;
        border: none;
        min-width: 150px;
        padding-left: 0;
        padding-right: 0;
      }
    `}
`

const baseCheckboxRadioStyle = `
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  cursor: pointer;

  .label {
    margin: 0;
    text-transform: none;
    font-size: ${typography.sizes.small};
    max-width: ${maxWidths.base};
  }

  input {
    vertical-align: sub;
    margin: 0 1.2rem 0 0;
    width: 28px;
    height: 28px;
    border: 1px solid ${colors.borderColor};
    background: ${colors.white};
    appearance: none;
    position: relative;

    &:focus {
      outline: ${colors.primaryDarkBlue};
    }

    &:checked {
      border-color: rgba(0, 49, 69, 0.7);

      & ~ .checkmark { 
        &:after {
          opacity: 1;
        }
      }
    }
    
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;

    &:after {
      content: "";
      position: absolute;
      opacity: 0;
    }
  }
`

StyledForm.Checkbox = styled.div`
  ${baseCheckboxRadioStyle};

  input {
    border-radius: ${radius.small};

    &:focus {
      outline: ${colors.primaryDarkBlue};
    }

    &:checked ~ .checkmark {
      border-radius: ${radius.small};
    }
  }
  .checkmark:after {
    left: 9px;
    top: 4px;
    width: 7px;
    height: 13px;
    border: solid rgba(0, 49, 69, 0.7);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

StyledForm.Radio = styled.div`
  ${baseCheckboxRadioStyle};

  input {
    border-radius: 100%;

    &:focus {
      outline: ${colors.primaryDarkBlue};
    }

    &:checked ~ .checkmark {
      border-radius: 100%;
    }
  }

  .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: rgba(0, 49, 69, 0.7);
  }
`

StyledForm.Select = styled.select`
  width: 100%;
  position: relative;
  appearance: none;
  outline: none;
  font-family: ${typography.primaryFont};
  font-size: ${typography.sizes.small};
  border: 1px solid ${colors.borderColor};
  background: ${colors.white};
  background-image: url(${caretDown});
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  padding: 1.2rem 2.4rem 1.2rem 1.2rem;
  border-radius: ${radius.small};
  max-width: ${maxWidths.input};

  @media ${mq.mobile} {
    font-size: 16px;
  }

  ${props =>
    props.multiple &&
    css`
      & {
        background-image: none;
        padding-right: ${spacing.base};
      }
    `}
`

StyledForm.Label = styled.label`
  display: block;
  margin-bottom: ${spacing.xSmall};
`

StyledForm.TextArea = styled.textarea`
  ${baseInputStyle};
  max-width: ${maxWidths.input};

  @media ${mq.mobile} {
    max-width: 100%;
    font-size: 16px;
  }

  &:focus {
    outline: ${colors.primaryDarkBlue};
  }

  min-height: 200px;
`

StyledForm.Group = styled.div`
  margin-bottom: ${spacing.large};

  @media ${mq.mobile} {
    margin-bottom: ${spacing.medium};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

StyledForm.Error = styled.p`
  margin: ${spacing.small} 0 0 0;
  color: ${colors.primaryRed};
  display: block;
  font-size: ${typography.sizes.small};
`

StyledForm.Description = styled.p`
  margin: ${spacing.small} 0 0 0;
  display: block;
  font-size: ${typography.sizes.small};
  color: rgba(0, 49, 69, 0.7);
`

StyledForm.InputWrapper = styled.div`
  position: relative;
`

StyledForm.DateWrapper = styled.div`
  /* Style datepicker popup below */
  .react-datepicker {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    border: none;
    font-family: ${typography.secondaryFont};
    font-size: ${typography.sizes.small};

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      width: 16px;
      height: 16px;
      border-radius: 999px;
      background: ${colors.secondaryLightBlue};
    }

    &::after {
      content: '';
      position: absolute;
      top: 10px;
      right: 10px;
      width: 16px;
      height: 16px;
      border-radius: 999px;
      background: ${colors.secondaryLightBlue};
    }
  }

  & .react-datepicker-popper {
    margin: 0;
  }

  & .react-datepicker__triangle {
    display: none;
  }

  & .react-datepicker__current-month {
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: 500;
    font-size: ${typography.sizes.small};
  }

  & .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    font-size: ${typography.sizes.small};
    padding-top: 14px;
  }

  & .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    top: 13px;
    height: 8px;
    width: 8px;
    border: 0.5rem solid transparent;

    &:active,
    &:focus {
      outline: none;
    }
    @media ${mq.tablet} {
      top: 14px;
    }
  }

  & .react-datepicker__navigation--next {
    border-left-color: ${colors.primaryDarkBlue};
    right: 10px;

    @media ${mq.tablet} {
      right: 11px;
    }
  }

  & .react-datepicker__navigation--previous {
    border-right-color: ${colors.primaryDarkBlue};
    left: 10px;

    @media ${mq.tablet} {
      left: 11px;
    }
  }

  & .react-datepicker__day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 23px;
    width: 23px;
    border-radius: 999px;
    color: rgba(0, 49, 69, 0.7);
    margin: ${spacing.xxxSmall} 6px;
  }

  & .react-datepicker__day--keyboard-selected {
    background: transparent;
  }

  & .react-datepicker__day--today {
    color: ${colors.primaryDarkBlue};
    border: 1px solid ${colors.secondaryYellow};
    background: transparent;
  }

  & .react-datepicker__day--selected {
    color: ${colors.primaryDarkBlue};
    background: ${colors.secondaryYellow};
  }

  & .react-datepicker__day-names {
    margin-top: ${spacing.xSmall};
  }

  & .react-datepicker__day-name {
    width: 23px;
    margin: ${spacing.xxSmall} 6px;
  }
`

export default StyledForm
